import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ReplaceCellRendererComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/model-list/replace-btn-cell-render';
import { ModelListService } from 'src/app/internal-vpacs/services-internal/tracking/model-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-replace-part-confirmation',
  templateUrl: './replace-part-confirmation.component.html',
  styleUrls: ['./replace-part-confirmation.component.scss']
})
export class ReplacePartConfirmationComponent implements OnInit {


  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 25;
  isAccessible = false;
  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  pageNumber = 0;
  lastRowIndex = 0;
  rowCount: any;
  importCode: any;
  res: any;
  SupplierCode: any;
  psd: any;
  partNumber: any;
  newPartConfirm: any;
  paginationPageSize = 50;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  gridColumnApi: any;
  routeParams: any;
  errorArray: any[] = [];
  sessionIndex = 0;
  sessionReplacePart: any = {}
  viewAccess: any;
  errorMessage !: { errorCode: string; childErrorCode: string; };
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly service: ModelListService,
    public dialog: MatDialog,
    public spinnerService: NgxUiLoaderService,
    public state:ExportStateService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    }

  }
  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.routeParams = params;
      if (this.routeParams) {
        if (sessionStorage.getItem('replacePart')) {
          const sessionData = JSON.parse(sessionStorage.getItem('replacePart') || '');
          this.sessionReplacePart = sessionData;
        }
        this.checkAccess();
      }
    })
  }
  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  changePaginationValues(values: any) {
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = values?.rpPage;
    this.apiCall();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  replaceBtn(data: any) {
    const replaceObject = {
      "ID": this.routeParams.id,
      "PlantCode": this.routeParams.plantcode,
      "ModelCode": this.routeParams.modelcode,
      "LineNumber": this.sessionReplacePart[this.sessionIndex].lineNumber,
      "PkgStartDate": this.sessionReplacePart[this.sessionIndex].psd,
      "arg": 0,
      "hidPartNumber_0": data.hidPartNumber,
      "hidSupplierCode_0": data.hidSupplierCode,
      "hidPSD_0": data.hidPSD,
      "rdoName_0": "ReplacePart"
    }
    this.spinnerService.start();
    this.service.replaceBtn(replaceObject).subscribe({
      next: (res: any) => {
        if (res.status) {
          if (this.sessionIndex <= this.sessionReplacePart.length) {
            this.sessionIndex = this.sessionIndex + 1;
          }
          const { ID, ModelCode, PlantCode } = res.response
          if (res.redirect === 'DeletePartConfirmation') {
            this.router.navigate([`/model-list/delete-part/${ID}/${ModelCode}/${PlantCode}`]);
          } else if (res.redirect === 'ReplacePartConfirmation') {
            if (this.sessionIndex + 1  < this.sessionReplacePart.length) {              
              this.sessionIndex = this.sessionIndex + 1;  
              this.apiCall();
            }
          } else if (res.redirect === 'ImpPartConfirm') {
            this.router.navigate([`/model-list/confirm-import/${ID}/${ModelCode}/${PlantCode}`]);
          }
        } else {
          if (res.message.length > 0) {
            this.errorArray = res.message;
          }
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        setTimeout(() => {
          this.errorArray = [];
        }, 7000);
      }
    })
  }

  addBtn(type: string) {
    this.spinnerService.start();
    const body = {
      "ID": this.routeParams.id,
      "PlantCode": this.routeParams.plantcode,
      "ModelCode": this.routeParams.modelcode,
      "LineNumber": this.sessionReplacePart[this.sessionIndex].lineNumber,
      "PkgStartDate": this.sessionReplacePart[this.sessionIndex].psd,
      "command": type
    };
    this.service.replaceAdd(body).subscribe({
      next: (res: any) => {
        if (res.status) {         
          const { ID, ModelCode, PlantCode } = res.response
          if (res.redirect === 'DeletePartConfirmation') {
            this.router.navigate([`/model-list/delete-part/${ID}/${ModelCode}/${PlantCode}`]);
          } else if (res.redirect === 'ReplacePartConfirmation') {
            if (this.sessionIndex + 1  < this.sessionReplacePart.length) {              
              this.sessionIndex = this.sessionIndex + 1;  
              this.apiCall();
            }
          } else if (res.redirect === 'ImpPartConfirm') {
            this.router.navigate([`/model-list/confirm-import/${ID}/${ModelCode}/${PlantCode}`]);
          }
        } else {
          if (res.message.length > 0) {
            this.errorArray = res.message;
          }
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        setTimeout(() => {
          this.errorArray = [];
        }, 7000);
      }
    })
  }

  checkAccess(){
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.viewAccess = res.role?.p_trackingsave;
        if (this.viewAccess) {
          this.getColumnDefs();
          this.apiCall();
        } else {
          this.errorMessage = {errorCode : `API-403`,childErrorCode : '400'};
        }
      },
      error: (_err: any) => {
        this.viewAccess = false;
        this.errorMessage = {errorCode : `API-099`,childErrorCode : '400'};
      },
    });
  }

  getColumnDefs(){
    this.columnDefs = [
      {
        headerName: "supplier code ", field: "hidSupplierCode", sortable: true, unSortIcon: true, minWidth: 120,
      },
      {
        headerName: "Supplier name", field: "hidSupplierName", sortable: true, unSortIcon: true, minWidth: 90,
      },
      {
        headerName: "part#", field: "hidPartNumber", sortable: true, unSortIcon: true, minWidth: 90,
      },
      {
        headerName: "replaces", field: "", minWidth: 90,
        cellRendererFramework: ReplaceCellRendererComponent,
        cellRendererParams: {
          clicked: (data: any) => {
            this.replaceBtn(data);
          },
        },
      }
    ]
  }

  apiCall() {
    const body = {
      "ID": this.routeParams.id,
      "PlantCode": this.routeParams.plantcode,
      "ModelCode": this.routeParams.modelcode,
      "limit": this.rpPage,
      "page": this.cPage,
    }    
    this.spinnerService.start();
    this.gridApi?.showLoadingOverlay();
    this.service.replaceOnload(body).subscribe({
      next: (res: any) => {
        if (res && res.result.status && res.result.response.length > 0) {
          const result = res.result.response[0];
          const output: any[] = [];
          const tempMap: any = {};
          for (const item of result) {
            const fieldIDParts = item.fieldID.split('_');
            const prefix = fieldIDParts[0];
            const index = fieldIDParts[1];
            if (!tempMap[index]) {
              tempMap[index] = {};
            }
            tempMap[index][prefix] = item.fieldValue;
          }
          for (const key in tempMap) {
            output.push(tempMap[key]);
          }
          this.displayRowData = output;
          this.rowCount = this.displayRowData.length;
          this.totalRecords = res.pagination.TotalRecords;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        } else {
          this.gridApi?.showNoRowsOverlay();
        }
        this.gridApi?.hideOverlay();
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.displayRowData = [];
        this.rowCount = this.displayRowData.length;
        this.totalRecords = 0;
        this.tPage = 0;
        this.gridApi.hideOverlay();
        this.spinnerService.stop();
      },
      complete: () => {
        this.errorArray = [];
        this.spinnerService.stop();
      }
    });
  }

  routeToModelList() {
    this.router.navigate(['/model-list'])
  }
}
