import { Component, ElementRef, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PpfServiceInternal } from 'src/app/internal-vpacs/services-internal/ppf.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder } from '@angular/forms';
import { GridOptions } from 'ag-grid-community';
import { ExportStateService } from 'src/app/services/export-state.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SelectPartComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/ppf/select-part/select-part.component';
import { SelectPackageComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/ppf/select-package/select-package/select-package.component';
import { SelectPkgFabComponent } from '../../../../internal-vpacs-dialogs/select-pkg-fab/select-pkg-fab.component';
import { toNumber, roundEx, KgToPound, MmToInch, rounding, toNumtoFixed0, isnull } from './ppfCalculations';
import * as FileSaver from 'file-saver-es';
import { BreadcrumbComponent } from 'src/app/core/breadcrumb/breadcrumb.component';
import { ConstantsService } from 'src/app/services/constant.service';


@Component({
  selector: 'app-part-list-ppf',
  templateUrl: './part-list-ppf.component.html',
  styleUrls: ['./part-list-ppf.component.scss'],
  providers: [BreadcrumbComponent]
})
export class PartListPpfComponent implements OnInit {
  @ViewChild('PPF') PPF!: ElementRef;
  trckingUrl='tracking-list'
  supplierCode:any
  hidweightCalculations: any
  hidheightCalculation:any
  pkgDelete = false
  selectParams: any
  approvedate: any
  supplierdate: any
  regdate: any
  url: any
  body: any
  rate: any
  mapdata: any
  protected openModal = false;
  public readOnly = false;
  public metaData: any = {};
  public ppfData: any;
  public image: (SafeResourceUrl| null)[] = []
  public gridOptions: GridOptions = {}
  public gridOptions2: GridOptions = {}
  public customGridOptions: GridOptions[] = [];
  protected isMazda = false;
  public editMode = 0;
  public ERR_ARR: any[] = [];
  ppfForm = this.formbuilder.group(
    {
      hazmat: [''],
      broadcastPart: [''],
      dock: ['']
    }
  )
  errorList: string[] = [];
  selectedResponse: any
  ppfPassData: any;
  imageNames: any[] = [];
  response: any;
  PPFCODE: any = ''
  public ISUPDATEPPF = 3;
  formatError: any[] = [];
  weightArray: any;
  contW: any = 0;
  dun1W: any = 0;
  dun2W: any = 0;
  topW: any = 0;
  pltW: any = 0;
  constPartNumber: any;
  ppfUpdate: any;
  userInfo: any;
  haveAccess: any;
  routeParams: any;
  storePackageTypeAPI:any;
  errorMessage:any;
  zoomImage:any=[]
  //new changes
  headers: string[] = [
    'COMPONENT NAME',
    'DESCRIPTION',
    'MANUFACTURER',
    'MATERIAL',
    'PACKAGE CODE',
    'LEAD TIME',
    'RET/EXP',
    'QUANTITY / CONTAINER',
    'COST ESTIMATE$(US)',
    'COMMENT'
  ];
  columns: number[] = [...Array(10).keys()];
  rows: number[] = [...Array(8).keys()];

  headers2 = [
    { label: 'COMPONENT', colspan: 1 },
    { label: 'LENGTH (mm)', colspan: 1 },
    { label: 'WIDTH (mm)', colspan: 1 },
    { label: 'HEIGHT (mm)', colspan: 1 },
    { label: 'COMPONENT', colspan: 1 },
    { label: 'WEIGHT (kg)', colspan: 1 },
    { label: 'QUANTITIES', colspan: 2 },
  ]
  columns2: number[] = [...Array(8).keys()];
  rows2: number[] = [...Array(6).keys()];

  // Define an array of objects to map values to columns
  data: any[] = [
    { col1: 'CONTAINER', col2: '', col3: '', col4: '', col6: '', col7: '', col8: '', col5: '', col9: '' },
    { col1: 'DUNNAGE 1', col2: '', col3: '', col4: '', col6: '', col7: '', col8: '', col5: '', col9: '' },
    { col1: 'DUNNAGE 2', col2: '', col3: '', col4: '', col6: '', col7: '', col8: '', col5: '', col9: '' },
    { col1: 'TOP CAP', col2: '', col3: '', col4: '', col6: '', col7: '', col8: '', col5: '', col9: '' },
    { col1: 'PALLET', col2: '', col3: '', col4: '', col6: '', col7: '', col8: '', col5: '', col9: '' },
    { col1: 'STRETCH WRAP/STRAP	', col2: '', col3: '', col4: '', col6: '', col7: '', col8: '', col5: '', col9: '' },
    { col1: 'KANBAN CARD HOLDER	', col2: '', col3: '', col4: '', col6: '', col7: '', col8: '', col5: '', col9: '' },
    { col1: 'OTHER', col2: '', col3: '', col4: '', col6: '', col7: '', col8: '', col5: '', col9: '' },
  ];
  tableData: any[] = [
    { column1: 'PART SIZE', column2: '', column3: '', column4: '', column5: 'PART', column8: '', column7: 'QTY PARTS/CONTAINER' },
    { column1: 'CONTAINER OUTSIDE DIMENSION', column2: '', column3: '', column5: 'DUNNAGE (TARE)', column4: '', column8: '', column7: 'MAXIMUM CONTAINER/LAYER ON PALLET' },
    { column1: 'CONTAINER INSIDE DIMENSION', column2: '', column3: '', column5: 'CONTAINER (TARE)', column4: '', column8: '', column7: 'MAXIMUM LAYERS/PALLET' },
    { column1: 'CONTAINER RETURN HEIGHT', column2: '', column3: '', column5: 'PALLET (TARE)', column4: '', column8: '', column7: 'SUPPLIER INVENTORY' },
    { column1: 'PALLET ONLY', column2: '', column3: '', column4: '', column5: 'CONTAINER GROSS(INCLUDE PARTS)	', column8: '', column7: 'SUPPLIER INTERNAL INVENTORY REQUIREMENT(DAYS)	' },
    { column1: 'PALLET LOAD AS SHIPPED', column2: '', column3: '', column5: 'PALLET LOAD GROSS(INCLUDE PARTS)	', column4: '', column8: '', column7: 'SUPPLIER INTERNAL INVENTORY ALLOCATION(DAYS)	' },
  ];
  form: any;
  form2: any;
  part: any;
  model: any;
  partdesc: any;
  asyncLoading = true;
  mode: any;
  dockDropDown: any;
  closecheckFlag :any
  copyPayload !: { PPFCode: any; PPFVer: any; selPPFCode: any; selPPFVer: any; SpecialistCode: any; PlantCode: any; PlantList: any; };
  specialCharError:any = [];
  constructor(
    private readonly route: Router,
    public _location: Location,
    private readonly dialog: MatDialog,
    public router: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public formbuilder: FormBuilder,
    public state: ExportStateService,
    private readonly spinner: NgxUiLoaderService,
    public internalPpfService: PpfServiceInternal,
    private readonly fb: FormBuilder,
    public breadcrumb: BreadcrumbComponent,
    public constantsService:ConstantsService,
  ) {
    this.form = this.fb.group({});
    for (let i = 0; i < this.rows.length; i++) {
      for (let j = 0; j < this.columns.length; j++) {
        if (j === 9 || j === 7 || j === 8 || j === 2 || j === 4) {
          this.form.addControl(`row${i}col${j}`, this.fb.control(''));
        }
      }
    }
   this.initiateForm2()
  }

  initiateForm2(){
    this.form2 = this.fb.group({});
    for (let i = 0; i < this.rows.length; i++) {
      for (let j = 0; j < this.columns.length; j++) {
        if ((j === 5 && i !== 0) || (j === 7 && i !== 3) || (j === 3 && i === 5)) {
          this.form2.addControl(`row${i}col${j}`, this.fb.control(''));
        }
      }
    }
  }

  ngOnInit(): void {
  this.closecheckFlag=sessionStorage.getItem('ppfcloseFromTracking')  
    this.errorMessage = null;
    this.url = this.breadcrumb.breadcrumbs.length > 0 && this.breadcrumb.breadcrumbs.at(-2)?.url;
    this.router.params.subscribe((data: any) => {
      this.routeParams = data;
      this.mode = Number(this.routeParams.mode);
    })
    this.spinner.start();
    this.asyncLoading = true;
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.userInfo = res;
          if (!res.role?.p_trackingview && !res.role?.p_costview && !res.role?.p_qtyview) {
            this.haveAccess = false;
            this.errorList = ["You're not authorized to access this page"];
            this.asyncLoading = false;
            this.spinner.stop();
          } else {
            this.errorList = [];
            this.haveAccess = true;
            this.onload();
          }
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.errorList.push(_err.message)
        this.spinner.stop();
        this.spinner.stop();
        this.asyncLoading = false;
      }
    });
    this.ppfUpdate = JSON.parse(sessionStorage.getItem('updatePPF') || '{}')
  }

  calcWeight() {
    const partw = toNumber(this.response.DisplayData[0].partweight);
    const contw = toNumber(this.hidweightCalculations.hidContWeight);
    const dun1w = toNumber(this.hidweightCalculations.dun1Weight);
    const dun2w = toNumber(this.hidweightCalculations.dun2Weight);
    const topw = toNumber(this.hidweightCalculations.topWeight);
    const palw = toNumber(this.hidweightCalculations.palWeight);

    const dun1q = toNumber(this.form.controls['row1col7']?.value);
    const dun2q = toNumber(this.form.controls['row2col7']?.value);
    const qpc = toNumber(this.form2.controls['row0col7']?.value);
    const mcl = toNumber(this.form2.controls['row1col7']?.value);
    const mlp = toNumber(this.form2.controls['row2col7']?.value);
    const dunweight = (dun1w * dun1q) + (dun2w * dun2q);
    const contgrossweigth = contw + dunweight + (partw * qpc);
    const palletloadweight = contgrossweigth * mcl * mlp + (palw + topw);
    if(this.userInfo?.info?.unit === "0") {
      this.hidweightCalculations.hidContWeight = roundEx(contw, 2)
      this.form2.controls['row5col5'].setValue(roundEx(palletloadweight, 2))
      this.form2.controls['row4col5'].setValue(roundEx(contgrossweigth, 2))
      this.form2.controls['row1col5'].setValue(roundEx(dunweight, 2))
    } else {
      this.hidweightCalculations.hidContWeight = roundEx(contw, 1)
      this.form2.controls['row5col5'].setValue( roundEx(KgToPound(roundEx(palletloadweight,1)), 1))
      this.form2.controls['row4col5'].setValue(roundEx(KgToPound(roundEx(contgrossweigth,1)), 1))
      this.form2.controls['row1col5'].setValue(roundEx(KgToPound(roundEx(dunweight,1)), 1))
    }
  }


  newcalcWeight() {
    const partw = toNumber(this.response.DisplayData[0].partweight);
    const contw = toNumber(this.hidweightCalculations.hidContWeight);
    const dun1w = toNumber(this.hidweightCalculations.dun1Weight);
    const dun2w = toNumber(this.hidweightCalculations.dun2Weight);
    const topw = toNumber(this.hidweightCalculations.topWeight);
    const palw = toNumber(this.hidweightCalculations.palWeight);
    const dun1q = toNumber(this.form.controls['row1col7']?.value);
    const dun2q = toNumber(this.form.controls['row2col7']?.value);
    const qpc = toNumber(this.form2.controls['row0col7']?.value);
    const mcl = toNumber(this.form2.controls['row1col7']?.value);
    const mlp = toNumber(this.form2.controls['row2col7']?.value);
    const dunweight = (dun1w * dun1q) + (dun2w * dun2q);
    const contgrossweigth = contw + dunweight + (partw * qpc);
    const palletloadweight = contgrossweigth * mcl * mlp + (palw + topw);
    this.form2.controls['row3col5'].setValue(this.setdimensions(palw, 1))
    this.form2.controls['row1col5'].setValue(this.setdimensions(dunweight, 1))
    this.form2.controls['row2col5'].setValue(this.setdimensions(contw, 1))
    this.form2.controls['row4col5'].setValue(this.setdimensions(contgrossweigth, 1))
    this.form2.controls['row5col5'].setValue(this.setdimensions(palletloadweight, 1))
    if (contgrossweigth >= 15) {
      //show input tag as red
    }
  }
  

  changePallet() {
    const MCP = this.form2.controls['row1col7'].value;
    const MLP = this.form2.controls['row2col7'].value;
    const data = (MCP * MLP);
    if (data === 0) {
      this.form.controls['row3col7'].patchValue(0);
      this.form.controls['row4col7'].patchValue(0);
    } else {
      const palletqty = roundEx(1 / (MCP * MLP), 2);
      this.form.controls['row3col7'].patchValue(palletqty);
      this.form.controls['row4col7'].patchValue(palletqty);
    }
    this.calcWeight();
    const ch = toNumber(this.tableData[1].column4);    
    const th = toNumber(this.hidheightCalculation.hidtopHeight);
    const ph = toNumber(this.hidheightCalculation.hidpalHeight);
				if(this.userInfo?.info?.unit === "0") {
          const lh = (ch * toNumber(MLP)) + th + ph;
          this.form2.controls['row5col3'].patchValue(roundEx(lh, 0))
				} else {
          const lh = (ch * toNumber(MLP)) + MmToInch(th) + MmToInch(ph);
          this.form2.controls['row5col3'].patchValue(roundEx((lh), 1))
				}
  }
  changeReq() {
    const pattern = /[^0-9.]+/
    if (pattern.test(this.form2.controls['row4col7'].value)) {
      alert("Invalid value.");
      this.form2.controls['row4col7'].value = 0;
      this.form2.controls['row5col7'].value = 2;
      return;
    }
    if (this.form2.controls['row4col7'].value > 2) {
      this.form2.controls['row5col7'].value = 2;
    } else if (this.form2.controls['row4col7'].value < 2) {
      this.form2.controls['row5col7'].value = this.form2.controls['row4col7'].value;
    } else {
      this.form2.controls['row5col7'].value = 2;
    }
  }

  onInputChange(row: number, col: number, _event: Event) {
    if ([ 1, 2].includes(row) && col === 7) {
      this.calcWeight();
    }
  }

  onInputChange2(row: number, col: number, _event: Event) {
    if ([0].includes(row) && col === 7) {
      this.calcWeight()
    }

    if ([1, 2].includes(row) && col === 7) {
      this.changePallet();
    }
    if ([4].includes(row) && col === 7) {
      this.changeReq();
    }
  }

  onButtonClick(row: number, col: number) {
    if (col === 2) {
      this.form.controls[`row${row}col2`].setValue('');
      this.form.controls[`row${row}col8`].setValue();
    }
    if (col === 4) {
      this.form.controls[`row${row}col4`].setValue();
     
      this.data[row].col2 = '';
      this.data[row].col4 = '';
      this.data[row].col5 = '';
      this.data[row].col6 = '';
      this.data[row].col7 = '';
      this.data[row].col9 = '';
      this.selectParams.PkgCode = this.form.controls[`row${row}col4`].value
      this.selectParams.FabCode = this.form.controls[`row${row}col2`].value
      this.selectParams.TopPkgCode = this.form.controls[`row3col4`].value
      this.selectParams.PalPkgCod = this.form.controls[`row4col4`].value
      this.resetFieldsOnDeleteForGrid2(row, col)
    }
  }

  async resetFieldsOnDeleteForGrid2(row: number, _col: number) {
    if (row === 0) {  //need to check this set
      await this.selectOnload(0, row)
      this.pkgDelete = true
      this.form.controls[`row${row}col8`].setValue();

      this.hidweightCalculations.hidContWeight = ''
      // grid 1
      this.form.controls[`row6col7`].setValue('');
      // grid 2
      this.tableData[1].column2 = ''
      this.tableData[1].column3 = ''
      this.tableData[1].column4 = ''
      this.tableData[2].column2 = ''
      this.tableData[2].column3 = ''
      this.tableData[2].column4 = ''
      this.tableData[3].column4 = ''
      this.form2.controls[`row1col7`].setValue('');
      this.form2.controls[`row2col7`].setValue('');
      this.form2.controls[`row2col5`].setValue('');  // need to check this with old app 
    } else if (row === 1) {
      await this.selectOnload(0, row)
      this.hidweightCalculations.dun1Weight = '' // Dunnage1 Weight
    } else if (row === 2) {
      await this.selectOnload(0, row)
      this.hidweightCalculations.dun2Weight = '' // Dunnage2 Weight
    } else if (row === 3) {
      await this.selectOnload(1, row)
      this.hidweightCalculations.topWeight = '' // TopCap Weight
    } else if (row === 4) {
      await this.selectOnload(2, row)
      this.hidweightCalculations.palWeight = ''
      // grid2
      this.tableData[4].column2 = ''
      this.tableData[4].column3 = ''
      this.tableData[4].column4 = ''
      this.form2.controls[`row3col5`].setValue();
    } else if (row === 5) {
      await this.selectOnload(0, row)
    } else if (row === 6) {
      await this.selectOnload(0, row)
    } else if (row === 7) {
      await this.selectOnload(0, row)
    }
    this.newcalcWeight()
    this.setLoadPalletHeight(row)
  }

  disableCheck(data: any) {
    //row1
    data.txtContManufacturerAttrReadOnly && this.form.get('row0col2').disable();
    data.txtContPkgAttrReadOnly && this.form.get('row0col4').disable();
    data.txtContQtyAttrReadOnly && this.form.get('row0col7').disable();
    data.txtContCostAttrReadOnly && this.form.get('row0col8').disable();
    data.txtContCommentAttrReadOnly && this.form.get('row0col9').disable();
    //row2
    data.txtDun1ManufacturerAttrReadOnly && this.form.get('row1col2').disable();
    data.txtDun1PkgAttrReadOnly && this.form.get('row1col4').disable();
    data.txtDun1QtyAttrReadOnly && this.form.get('row1col7').disable();
    data.txtDun1CostAttrReadOnly && this.form.get('row1col8').disable();
    data.txtDun1CommentAttrReadOnly && this.form.get('row1col9').disable();
    this.row3_4_5(data)
    this.row6_7_8(data)
    return true;
  }
  setDisableForm2() {
    const form2Keys = Object.keys(this.form2.controls)
    for (const i of form2Keys) {
      if (['row5col5', 'row1col7', 'row2col7', 'row0col7'].includes(i)) {
        continue;
      } else {
        this.form2.controls[i]?.disable()
      }
    }
  }

  setDisable() {
    this.mapdata = [
      { key: "row0col8", val: this.response.DisplayData[0]?.concostestimate },
      { key: "row1col8", val: this.response.DisplayData[0]?.dun1costestimate },
      { key: "row2col8", val: this.response.DisplayData[0]?.dun2costestimate },
      { key: "row3col8", val: this.response.DisplayData[0]?.topcostestimate },
      { key: "row4col8", val: this.response.DisplayData[0]?.palcostestimate },
      { key: "row5col8", val: this.response.DisplayData[0]?.strcostestimate },
      { key: "row6col8", val: this.response.DisplayData[0]?.kancostestimate },
      { key: "row7col8", val: this.response.DisplayData[0]?.othcostestimate },
    ]
    let j = 0;
    for (const i of this.mapdata) {
      if (this.userInfo.role.p_costview && this.isCostView(this.response?.PlantCode)) {
        const cal = this.nullDouble(this.form.controls[i.key].value * this.rate) === 0 ? null : this.nullDouble(this.form.controls[i.key].value * this.rate).toFixed(2);
        this.form.controls[i.key].patchValue(cal)
        this.data[j].col9 = cal
        j++;
      } else {
        this.form.controls[i.key]?.disable()
      }
    }
  }

  nullDouble(val: any) {
    if (val === null || val === undefined) {
      return 0;
    } else {
      return isNaN(Number(val)) ? 0 : Number(val)
    }
  }

  row3_4_5(data: any) {
    //row3
    data.txtDun2ManufacturerAttrReadOnly && this.form.get('row2col2').disable();
    data.txtDun2PkgAttrReadOnly && this.form.get('row2col4').disable();
    data.txtDun2QtyAttrReadOnly && this.form.get('row2col7').disable();
    data.txtDun2CostAttrReadOnly && this.form.get('row2col8').disable();
    data.txtDun2CommentAttrReadOnly && this.form.get('row2col9').disable();
    //row4
    data.txtTopManufacturerAttrReadOnly && this.form.get('row3col2').disable();
    data.txtTopPkgAttrReadOnly && this.form.get('row3col4').disable();
    data.txtTopQtyAttrReadOnly && this.form.get('row3col7').disable();
    data.txtTopCostAttrReadOnly && this.form.get('row3col8').disable();
    data.txtTopCommentAttrReadOnly && this.form.get('row3col9').disable();
    //row5
    data.txtPalManufacturerAttrReadOnly && this.form.get('row4col2').disable();
    data.txtPalPkgAttrReadOnly && this.form.get('row4col4').disable();
    data.txtPalQtyAttrReadOnly && this.form.get('row4col7').disable();
    data.txtPalCostAttrReadOnly && this.form.get('row4col8').disable();
    data.txtPalCommentAttrReadOnly && this.form.get('row4col9').disable();
  }

  row6_7_8(data: any) {
    //row6
    data.txtStrManufacturerAttrReadOnly && this.form.get('row5col2').disable();
    data.txtStrPkgAttrReadOnly && this.form.get('row5col4').disable();
    data.txtStrQtyAttrReadOnly && this.form.get('row5col7').disable();
    data.txtStrCostAttrReadOnly && this.form.get('row5col8').disable();
    data.txtStrCommentAttrReadOnly && this.form.get('row5col9').disable();
    //row7
    data.txtKanManufacturerAttrReadOnly && this.form.get('row6col2').disable();
    data.txtKanPkgAttrReadOnly && this.form.get('row6col4').disable();
    data.txtKanQtyAttrReadOnly && this.form.get('row6col7').disable();
    data.txtKanCostAttrReadOnly && this.form.get('row6col8').disable();
    data.txtKanCommentAttrReadOnly && this.form.get('row6col9').disable();
    //row8
    data.txtOthManufacturerAttrReadOnly && this.form.get('row7col2').disable();
    data.txtOthPkgAttrReadOnly && this.form.get('row7col4').disable();
    data.txtOthQtyAttrReadOnly && this.form.get('row7col7').disable();
    data.txtOthCostAttrReadOnly && this.form.get('row7col8').disable();
    data.txtOthCommentAttrReadOnly && this.form.get('row7col9').disable();
    // this.setDisable()
  }

  menuClick(row: number, col: number) {
    if (col === 2) {
      const dialog = this.dialog.open(SelectPkgFabComponent, { data: { row: row + 1, userInfo: this.userInfo, packageCode: this.data[row].col5 }, width: '90%' })
      dialog.afterClosed().subscribe((res: any) => {
        if (res) {
          this.form.controls[`row${row}col2`].setValue(res.data.rowData.alphacode);
        }
      })
    }
  }


  genereteOnloadPayload(){
    if (this.userInfo.info.unit === '1') {
      this.headers2[1]['label'] = 'LENGTH (inch)'
      this.headers2[2]['label'] = 'WIDTH (inch)'
      this.headers2[3]['label'] = 'HEIGHT (inch)'
      this.headers2[5]['label'] = 'WEIGHT (lb)'
    }
    //uncomment below object while pushing the code
    if(this.url.includes('tracking-list/update-ppf-reason') && this.mode===2){
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.routeParams?.ppfver,
        "History": this.routeParams?.history,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList
      }
    }else{
      this.generetePayloadAgain()
    }
    
    if (this.url.includes('tracking-list/tracking-history')) {
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.routeParams?.ppfver,
        "History": this.routeParams?.history,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": [this.userInfo?.role?.p_plantList?.plantcode]
      }
    }

  }

  generetePayloadAgain(){
    if(this.url.includes('tracking-list/update-ppf-reason') && this.mode===1){
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.routeParams?.ppfver,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList
      }
    }else if (this.url.includes('tracking-list/send-dept-approval') || this.url.includes('part-list') || this.url.includes('tracking-list/sI-preppf-dept-approval') || this.url.includes('tracking-list/sI-final-dept-approval') || this.url.includes('tracking-list/si-pkgtrial-dept-approval') || this.url.includes('/tracking-list/hazmat-approval')) {
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.routeParams?.ppfver,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList
      }

    } else if (this.url.includes('tracking-list/send-ppf') || this.url.includes('tracking-list/trial-order') || this.url.includes('dept-approve') || this.url.includes('discripency-list') || (this.url.includes('benchmark') && this.mode === 2)) {
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList
      }
    } else if ((this.url.includes(this.trckingUrl) && this.mode === 2) || this.url.includes('pur-decision-list') || this.url.includes('decision-list')) {
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList
      }
    } else if ((this.url.includes(this.trckingUrl)) || (this.url.includes('benchmark'))) {
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.routeParams?.ppfver,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList
      }
    }
    else {
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.routeParams?.ppfver,
        "History": this.routeParams?.history,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList
      }
    }
  }

  checkPlantLevelAccess(ipPlant:string){
    let retunValue = false;
    this.state.userInfoState.subscribe({
      next : (res:any) =>{
        if(res){
          if(res.role.p_plantList.length>0){
            const plantList = res.role.p_plantList;
            retunValue =  plantList.some((x:any) => x.plantcode === ipPlant)
          }else{
            retunValue = false;
          }
        }
      }
    })
    return retunValue;
  }

  onload() {
    this.genereteOnloadPayload()
    this.internalPpfService.OnloadPPFData(this.body).subscribe({
      next: (res: any) => {
        const havePlantAccess = this.checkPlantLevelAccess(res.PlantCode);
        if(!havePlantAccess){
          this.asyncLoading = false;
          this.haveAccess = false;
          this.errorList = ["Part Number and Supplier code does not match"];
          return;
        }
        this.haveAccess = true;
        this.errorList = [];
        this.response = res;
        this.onGettingResponse(res,'onload')
        this.spinner.stop();
      },
      error: (_error: any) => {
        this.asyncLoading = false;
        this.spinner.stop();
      }
    });
  }

  onGettingResponse(res:any,type:string){
    const defaultDateFormat='0001-01-01'
    if (res && res.DisplayData && res.DisplayData.length > 0) {
      if(type !== 'copy'){
        this.ppfData = res.DisplayData[0];
        this.dockDropDown = res?.DockList;
      }
      this.initCalcVars();
    this.rate = this.ppfData?.Rate
    this.constPartNumber = this.ppfData?.partnumber
    this.supplierdate = (this.ppfData?.supplierdate && !this.ppfData?.supplierdate.includes(defaultDateFormat)) ? this.ppfData?.supplierdate?.substring(0, 10) : null
    this.approvedate = (this.ppfData?.approvedate && !this.ppfData?.approvedate.includes(defaultDateFormat)) ? this.ppfData?.approvedate?.substring(0, 10) : null
    this.regdate = (this.ppfData?.regdate && !this.ppfData?.regdate.includes(defaultDateFormat)) ? this.ppfData?.regdate?.substring(0, 10) : null
    this.part = this.ppfData.replacespartnumber
    this.imageNames = [res.DisplayData[0]?.picpart, res.DisplayData[0]?.picdunnage, res.DisplayData[0]?.piccontrack, res.DisplayData[0]?.picpallet]
    for (let index = 0; index < 4; index++) {
      if(type === 'copy' && index !== 0){
        if(this.imageNames[index]){
          this.getCopyImages(index,this.imageNames[index],this.copyPayload.selPPFCode,this.copyPayload.selPPFVer);
        }else{
          this.image[index] = null
        }
      }else if(type === 'onload'){
        this.imageNames[index] && this.getOnLoadImages(index);
      }
    }
    this.setValues(res.DisplayData[0],type);
    this.setGridOne(res.DisplayData[0]);
    this.setGridTwo(res.DisplayData[0]);
    this.response.BtnHidden && this.disableCheck(res.BtnHidden);
    this.form.get('row4col7').disable()
    this.form.get('row3col7').disable()
    this.form.get('row0col7').disable()
    this.form.get('row6col7').disable()
    this.form.get('row3col7').updateValueAndValidity()
    this.setDisable()
    this.asyncLoading = false;
  }
  }

  initCalcVars(){
    if(Number(this.response.PPFVer) < 1) {
      this.supplierCode = this.ppfData?.plantcode === this.constantsService.tmmcPlant ? (`${this.ppfData?.suppliercode},${this.ppfData?.tmmccode}`) : this.ppfData?.suppliercode;
    } else {
      this.supplierCode = this.getSupplierCode()
    }
    if( this.supplierCode && this.supplierCode.trim().toLowerCase().startsWith('j')){
      this.isMazda=true
    }
    this.hidheightCalculation={
     hidtopHeight: this.ppfData?.topHeight,
     hidpalHeight: this.ppfData?.palHeight
    }
    this.hidweightCalculations = {
      hidContWeight: toNumber(this.ppfData?.contWeight),
      dun1Weight: toNumber(this.ppfData?.dun1Weight),
      dun2Weight: toNumber(this.ppfData?.dun2Weight),
      topWeight: toNumber(this.ppfData?.topWeight),
      palWeight: toNumber(this.ppfData?.palWeight)
    }
    this.selectParams = {
      TopPkgCode: this.ppfData?.toppackagecode,
      PalPkgCode: this.ppfData?.palpackagecode,
      PlantCode: this.userInfo.info.plantcode
    }
  }

  getSupplierCode() {
    let sup = null;
    if (Number(this.userInfo.role.supType) === 0) {
      return this.ppfData?.plantcode === this.constantsService.tmmcPlant ? (`${this.ppfData?.suppliercode},${this.ppfData?.tmmccode}`) : this.ppfData?.suppliercode;
    } else if (Number(this.userInfo.role.supType) === 1) {
      sup = this.ppfData?.tmmccode
      if (sup === "" || sup === null) {
        return this.ppfData?.suppliercode;
      }
      return this.ppfData?.plantcode === this.constantsService.tmmcPlant ? (`${this.ppfData?.suppliercode},${sup}`) : sup;
    } else if (Number(this.userInfo.role.supType) === 2) {
      sup = this.ppfData?.nummicode
      if (sup === "" || sup === null) {
        return this.ppfData?.suppliercode;
      }
      return this.ppfData?.plantcode === '21TMC' ? (`${this.ppfData?.suppliercode},${this.ppfData?.tmmccode}`) : sup;
    } else {
      return "";
    }
  }

  getImagePayload(imageNumber: number) {
    let payload = {}
    if (this.url.includes('tracking-list/send-dept-approval') || this.url.includes('part-list') || this.url.includes('tracking-list/sI-preppf-dept-approval') || this.url.includes('tracking-list/sI-final-dept-approval') || this.url.includes('tracking-list/si-pkgtrial-dept-approval')) {
      payload = {
        "PPFCode": this.routeParams?.ppfcode,
        "ImgName": this.imageNames[imageNumber],
        "ImgNum": imageNumber + 1,
        "PPFVer": this.routeParams?.ppfver,
        "ResPPFVer": ''
      }
    } else if (this.url.includes('tracking-list/send-ppf') || this.url.includes('tracking-list/trial-order') || this.url.includes('dept-approve') || this.url.includes('discripency-list') || (this.url.includes('benchmark') && this.mode === 2)) {
      payload = {
        "PPFCode": this.routeParams?.ppfcode,
        "ImgName": this.imageNames[imageNumber],
        "ImgNum": imageNumber + 1,
        "PPFVer": '',
        "ResPPFVer": this.response?.PPFVer
      }
    } else if ((this.url.includes(this.trckingUrl) && this.mode === 2) || this.url.includes('pur-decision-list') || this.url.includes('decision-list')) {
      payload = {
        "PPFCode": this.routeParams?.ppfcode,
        "ImgName": this.imageNames[imageNumber],
        "ImgNum": imageNumber + 1,
        "PPFVer": '',
        "ResPPFVer": this.response?.PPFVer
      }
    } else if ((this.url.includes(this.trckingUrl)) || (this.url.includes('benchmark'))) {
      payload = {
        "PPFCode": this.routeParams?.ppfcode,
        "ImgName": this.imageNames[imageNumber],
        "ImgNum": imageNumber + 1,
        "PPFVer": this.routeParams?.ppfver,
        "ResPPFVer": ''
      }
    } else {
      payload = {
        "PPFCode": this.routeParams?.ppfcode,
        "ImgName": this.imageNames[imageNumber],
        "ImgNum": imageNumber + 1,
        "PPFVer": this.routeParams?.ppfver,
        "ResPPFVer": ''
      }

    }
    if (this.url.includes('tracking-list/tracking-history')) {
      payload = {
        "PPFCode": this.routeParams?.ppfcode,
        "ImgName": this.imageNames[imageNumber],
        "ImgNum": imageNumber + 1,
        "PPFVer": this.routeParams?.ppfver,
        "ResPPFVer": ''
      }
    }
    return payload
  }

  openWindow(i:any){
    const data = i;
    const w = window.open('about:blank');
    const image = new Image();
    image.src = data;
    setTimeout(function(){
      w?.document.write('<style> img{max-width:100%; height:auto;}</style>'+image.outerHTML);
    }, 0);
  }

  getCopyImages(imageNumber:number,imageName:string,ppfcode:string,ppfver:string){
    const payload = {
      "PPFCode": ppfcode,
      "ImgName": imageName,
      "ImgNum": imageNumber + 1,
      "PPFVer": ppfver
    }
    this.internalPpfService.copyPPFImage(payload).subscribe({
      next: (res: any) => {
        if (res && res.data) {
          const data = res.data;
          if (data) {
            const image = this.createBase64Image(data);
            this.image[imageNumber] = image;
            this.zoomImage[imageNumber]=`data:image/jpg;base64,${data}`

          } else {
            this.image[imageNumber] = null;
          }
        } else {
          this.image[imageNumber] = null;
        }
      },
    })
}

  getOnLoadImages(imageNumber: number) {
    const payload = this.getImagePayload(imageNumber)
    this.internalPpfService.onLoadImage(payload).subscribe({
      next: (res: any) => {
        if (res && res.data) {
          const data = res.data;
          if (data) {
            const image = this.createBase64Image(data);
            this.image[imageNumber] = image;
            this.zoomImage[imageNumber]=`data:image/jpg;base64,${data}`

          } else {
            this.image[imageNumber] = null;
          }
        } else {
          this.image[imageNumber] = null;
        }
      },
    })
  }

  isCostView(plantCode: any) {
    const plantList = this.userInfo.role.p_plantList;
    if (plantList === null || plantList === undefined) {
      return false;
    } else {
      for (const i of plantList) {
        if (plantCode === i["plantcode"]) {
          return true;
        }
      }
    }
    return false;
  }

  setGridTwo(data: any) {
    this.setDisableForm2();
    if(this.internalPpfService.ppfTable2Data()){
      this.form2.patchValue(this.internalPpfService.ppfTable2Data());
      this.tableData = this.internalPpfService.NEtable2Data;
      return;
    }
    this.form2.controls['row0col7']?.patchValue(toNumtoFixed0(data?.qtypartcontrack));
    this.form2.controls['row1col5']?.patchValue(this.setdimensions(data?.dunnageweight, 1));
    this.form2.controls['row1col7']?.patchValue(toNumtoFixed0(data?.qtymaxcontlayer));
    this.form2.controls['row2col5']?.patchValue(this.setdimensions(data?.contWeight, 1));
    this.form2.controls['row2col7']?.patchValue(toNumtoFixed0(data?.qtymaxlayerpallet));
    this.form2.controls['row3col5']?.patchValue(this.setdimensions(data?.palletweight, 1));
    this.form2.controls['row4col5']?.patchValue(this.setdimensions(data?.contrackgrossweight, 1));
    this.form2.controls['row4col7']?.patchValue(toNumtoFixed0(data?.supinventoryreq));
    this.form2.controls['row5col5']?.patchValue(this.setdimensions(data?.palletloadgrossweight, 1));
    this.form2.controls['row5col7']?.patchValue(toNumtoFixed0(data?.supinventoryallo));
    this.form2.controls['row5col3']?.patchValue(this.setdimensions(data?.palletloadh, 0));
    this.setNonEditableGrid2(data)
  }

  setNonEditableGrid2(data: any) {
    //row1
    this.tableData[0].column2 = this.setdimensions(data?.partl, 0);
    this.tableData[0].column3 = this.setdimensions(data?.partw, 0);
    this.tableData[0].column4 = this.setdimensions(data?.parth, 0);
    this.tableData[0].column6 = this.setdimensions(data?.partweight, 3);
    this.tableData[0].column8 = data?.qtypartcontrack;
    //row2
    this.tableData[1].column2 = this.setdimensions(data?.contl, 0);
    this.tableData[1].column3 = this.setdimensions(data?.contw, 0);
    this.tableData[1].column4 = this.setdimensions(data?.conth, 0);
    this.tableData[1].column6 = this.setdimensions(data?.dunnageweight, 1);
    this.tableData[1].column8 = data?.qtymaxcontlayer;
    //row3
    this.tableData[2].column2 = this.setdimensions(data?.incontl, 0);
    this.tableData[2].column3 = this.setdimensions(data?.incontw, 0);
    this.tableData[2].column4 = this.setdimensions(data?.inconth, 0);
    this.tableData[2].column6 = this.setdimensions(data?.contWeight, 1);
    this.tableData[2].column8 = data?.qtymaxlayerpallet;
    //row4
    this.tableData[3].column2 = '-';
    this.tableData[3].column3 = '-';
    this.tableData[3].column4 = this.setdimensions(data?.contreth, 0);
    this.tableData[3].column6 = this.setdimensions(data?.palletweight, 1);
    //row5
    this.tableData[4].column2 = this.setdimensions(data?.palletonlyl, 0);
    this.tableData[4].column3 = this.setdimensions(data?.palletonlyw, 0);
    this.tableData[4].column4 = this.setdimensions(data?.palletonlyh, 0);
    this.tableData[4].column6 = this.setdimensions(data?.contrackgrossweight, 1);
    this.tableData[4].column8 = toNumtoFixed0(data?.supinventoryreq);
    //row6
    this.tableData[5].column2 = this.setdimensions(data?.palletloadl, 0);
    this.tableData[5].column3 = this.setdimensions(data?.palletloadw, 0);
    this.tableData[5].column4 = this.setdimensions(`${data?.palletloadh}`, 0);
    this.tableData[5].column6 = this.setdimensions(data?.palletloadgrossweight, 1);
    this.tableData[5].column8 = toNumtoFixed0(data?.supinventoryallo);
  }

  setdimensions(data: any, flag: any) {
    if (this.userInfo.info.unit === '0') {
      return toNumber(data)
    } else {
      if (flag === 0) {
        return toNumber(MmToInch(toNumber(data))).toFixed(1)
      } else if (flag === 3) {
        return Number((KgToPound(toNumber(data)))).toFixed(4)
      } else {
        if (toNumber(data) === 0){
          return null
        }else{
          return Number(rounding(KgToPound(toNumber(data)))).toFixed(1)
        }
      }
    }
  }

  setGridOne(data: any) {
    if(this.internalPpfService.ppfTable1Data()){
      let newData = this.internalPpfService.ppfTable1Data();
      if(this.internalPpfService.newPackage.packagecode){
        newData[`row${this.internalPpfService.newPackage.row}col4`] = this.internalPpfService.newPackage.packagecode;
      }
      this.form.patchValue(newData);
      this.data = this.internalPpfService.NEtable1Data;
      // this.setNonEditableGrid1(data)
      return;
    }
    //row1
    this.form.controls['row0col2'].patchValue(data?.conmanufacturer);
    this.form.controls['row0col4'].patchValue(data?.conpackagecode);
   
    this.form.controls['row0col7'].patchValue(isnull(data?.conqty));
    this.form.controls['row0col8'].patchValue(isnull(data?.concostestimate));
    this.form.controls['row0col9'].patchValue(data?.connote);
    //row2
    this.form.controls['row1col2'].patchValue(data?.dun1manufacturer);
    this.form.controls['row1col4'].patchValue(data?.dun1packagecode);
    this.form.controls['row1col7'].patchValue(isnull(data?.dun1qty));
    this.form.controls['row1col8'].patchValue(isnull(data?.dun1costestimate));
    this.form.controls['row1col9'].patchValue(data?.dun1note);
    //row3
    this.form.controls['row2col2'].patchValue(data?.dun2manufacturer);
    this.form.controls['row2col4'].patchValue(data?.dun2packagecode);
    this.form.controls['row2col7'].patchValue(isnull(data?.dun2qty));
    this.form.controls['row2col8'].patchValue(isnull(data?.dun2costestimate));
    this.form.controls['row2col9'].patchValue(data?.dun2note);
    //row4
    this.form.controls['row3col2'].patchValue(data?.topmanufacturer);
    this.form.controls['row3col4'].patchValue(data?.toppackagecode);
    this.form.controls['row3col7'].patchValue(isnull(data?.topqty));
    this.form.controls['row3col8'].patchValue(isnull(data?.topcostestimate));
    this.form.controls['row3col9'].patchValue(data?.topnote);
    //row5
    this.form.controls['row4col2'].patchValue(data?.palmanufacturer);
    this.form.controls['row4col4'].patchValue(data?.palpackagecode);
    this.form.controls['row4col7'].patchValue(isnull(data?.palqty));
    this.form.controls['row4col8'].patchValue(isnull(data?.palcostestimate));
    this.form.controls['row4col9'].patchValue(data?.palnote);
    //row6
    this.form.controls['row5col2'].patchValue(data?.strmanufacturer);
    this.form.controls['row5col4'].patchValue(data?.strpackagecode);
    this.form.controls['row5col7'].patchValue(isnull(data?.strqty));
    this.form.controls['row5col8'].patchValue(isnull(data?.strcostestimate));
    this.form.controls['row5col9'].patchValue(data?.strnote);
    //row-7
    this.form.controls['row6col2'].patchValue(data?.kanmanufacturer);
    this.form.controls['row6col4'].patchValue(data?.kanpackagecode);
    
    this.form.controls['row6col7'].patchValue(isnull(data?.kanqty));
    this.form.controls['row6col8'].patchValue(isnull(data?.kancostestimate));
    this.form.controls['row6col9'].patchValue(data?.kannote);
    //row-8
    this.form.controls['row7col2'].patchValue(data?.othmanufacturer);
    this.form.controls['row7col4'].patchValue(data?.othpackagecode);
    this.form.controls['row7col7'].patchValue(isnull(data?.othqty));
    this.form.controls['row7col8'].patchValue(isnull(data?.othcostestimate));
    this.form.controls['row7col9'].patchValue(data?.othnote);
    //set-noneditable values
    this.setNonEditableGrid1(data);
  }

  setNonEditableGrid1(data: any) {
    //row1
    this.data[0].col2 = data?.condescription;
    this.data[0].col3 = data?.conmanufacturer;
    this.data[0].col4 = data?.conmaterial;
    this.data[0].col5 = data?.conpackagecode;
    this.data[0].col6 = toNumtoFixed0(data?.conleadtime);
    this.data[0].col7 = data?.conretexp;
    this.data[0].col8 = isnull(data?.conqty);
    this.data[0].col9 = isnull(data?.concostestimate);
    this.data[0].col10 = data?.connote;
    //row2
    this.data[1].col2 = data?.dun1description;
    this.data[1].col3 = data?.dun1manufacturer;
    this.data[1].col4 = data?.dun1material;
    this.data[1].col5 = data?.dun1packagecode;
    this.data[1].col6 = toNumtoFixed0(data?.dun1leadtime);
    this.data[1].col7 = data?.dun1retexp;
    this.data[1].col8 = isnull(data?.dun1qty);
    this.data[1].col9 = isnull(data?.dun1costestimate);
    this.data[1].col10 = data?.dun1note;
    //row3
    this.data[2].col2 = data?.dun2description;
    this.data[2].col3 = data?.dun2manufacturer;
    this.data[2].col4 = data?.dun2material;
    this.data[2].col5 = data?.dun2packagecode;
    this.data[2].col6 = toNumtoFixed0(data?.dun2leadtime);
    this.data[2].col7 = data?.dun2retexp;
    this.data[2].col8 = isnull(data?.dun2qty);
    this.data[2].col9 = isnull(data?.dun2costestimate);
    this.data[2].col10 = data?.dun2note;
    //row4
    this.data[3].col2 = data?.topdescription;
    this.data[3].col3 = data?.topmanufacturer;
    this.data[3].col4 = data?.topmaterial;
    this.data[3].col5 = data?.toppackagecode;
    this.data[3].col6 = toNumtoFixed0(data?.topleadtime);
    this.data[3].col7 = data?.topretexp;
    this.data[3].col8 = isnull(data?.topqty);
    this.data[3].col9 = isnull(data?.topcostestimate);
    this.data[3].col10 = data?.topnote;
    //row5
    this.data[4].col2 = data?.paldescription;
    this.data[4].col3 = data?.palmanufacturer;
    this.data[4].col4 = data?.palmaterial;
    this.data[4].col5 = data?.palpackagecode;
    this.data[4].col6 = toNumtoFixed0(data?.palleadtime);
    this.data[4].col7 = data?.palretexp;
    this.data[4].col8 = isnull(data?.palqty);
    this.data[4].col9 = isnull(data?.palcostestimate);
    this.data[4].col10 = data?.palnote;
    //row6
    this.data[5].col2 = data?.strdescription;
    this.data[5].col3 = data?.strmanufacturer;
    this.data[5].col4 = data?.strmaterial;
    this.data[5].col5 = data?.strpackagecode;
    this.data[5].col6 = toNumtoFixed0(data?.strleadtime);
    this.data[5].col7 = data?.strretexp;
    this.data[5].col8 = isnull(data?.strqty);
    this.data[5].col9 = isnull(data?.strcostestimate);
    this.data[5].col10 = data?.strnote;
    //row7
    this.data[6].col2 = data?.kandescription;
    this.data[6].col3 = data?.kanmanufacturer;
    this.data[6].col4 = data?.kanmaterial;
    this.data[6].col5 = data?.kanpackagecode;
    this.data[6].col6 = toNumtoFixed0(data?.kanleadtime);
    this.data[6].col7 = data?.kanretexp;
    this.data[6].col8 = isnull(data?.kanqty);
    this.data[6].col9 = isnull(data?.kancostestimate);
    this.data[6].col10 = data?.kannote;
    //row8
    this.data[7].col2 = data?.othdescription;
    this.data[7].col3 = data?.othmanufacturer;
    this.data[7].col4 = data?.othmaterial;
    this.data[7].col5 = data?.othpackagecode;
    this.data[7].col6 = toNumtoFixed0(data?.othleadtime);
    this.data[7].col7 = data?.othretexp;
    this.data[7].col8 = isnull(data?.othqty);
    this.data[7].col9 = isnull(data?.othcostestimate);
    this.data[7].col10 = data?.othnote;
  }

  createBase64Image(data: any) {
    return this.sanitizer.sanitize(SecurityContext.URL,`data:image/jpg;base64,${data}`);
  }

  navigatePrevious() {
    if(this.closecheckFlag==='0' ){
    sessionStorage.setItem('ppfcloseFromTracking','1')  
    window.close()
    }else{
    this._location.back();
    }
  }

  setValues(data: any,type:string) {
    this.ppfForm?.controls?.hazmat.setValue(data?.hazmat);
    if(type!=='copy'){
      this.ppfForm?.controls?.broadcastPart.setValue(data?.broadcastflag === '1' ? '1' : '0');
      this.ppfForm?.controls?.dock.setValue(data?.dockcode);
    }
    if (this.response.BtnHidden) {
      this.response.BtnHidden.selBroadcastPartDisable && this.ppfForm.get('broadcastPart')?.disable();
      this.response.BtnHidden.selDockDisable && this.ppfForm.get('dock')?.disable();
      this.response.BtnHidden.selHazmatDisable && this.ppfForm.get('hazmat')?.disable();
    }
    if (this.mode === 2) {
      this.ppfForm.controls.dock.disable();
      this.ppfForm.controls.dock.updateValueAndValidity();
    }
  }

  selectPart() {
    const dialogData = this.dialog.open(SelectPartComponent, { width: '100%', data: { partnumber: this.ppfData?.partnumber } });
    dialogData.afterClosed().subscribe((result: any) => {
      if (result) {
        this.copyPayload = {
          "PPFCode": this.routeParams?.ppfcode,
          "PPFVer": this.routeParams?.ppfver,
          "selPPFCode": result.data.ppfcode,
          "selPPFVer": (result.data.ppfver).toString(),
          "SpecialistCode": this.userInfo?.info?.specialistcode,
          "PlantCode": this.userInfo?.info?.plantcode,
          "PlantList": this.userInfo?.role?.p_plantList
        }
        this.internalPpfService.copyPPF(this.copyPayload).subscribe({
          next: (res: any) => {
            this.response = res;
            this.onGettingResponse(res,'copy')
            this.spinner.stop();
          },
          error: (_error: any) => {
            this.spinner.stop();
          }
        });
      }
    })
  }

  selectPackage(j: any) {
    const fab = this.form.controls[`row${j - 1}col2`]?.value;
    this.internalPpfService.setPpfTable1Data(this.form.getRawValue());
    this.internalPpfService.NEtable1Data = this.data;
    this.internalPpfService.setPpfTable2Data(this.form2.getRawValue());
    this.internalPpfService.NEtable2Data = this.tableData;
    this.internalPpfService.newPackage.packagecode = '';
    const dialogData = this.dialog.open(SelectPackageComponent, { data: { rownumber: j, unit: this.userInfo?.info?.unit, fab: fab }  });
    dialogData.afterClosed().subscribe((result: any) => {
      if (result) {
        const row = j - 1;
        this.selectParams.PkgCode = result.data?.packagecode
        this.selectParams.FabCode = this.form.controls[`row${row}col2`].value
        this.selectParams.TopPkgCode = this.form.controls[`row3col4`].value
        this.selectParams.PalPkgCode = this.form.controls[`row4col4`].value
        switch (row) {
          case 0:
            this.setContainerValues(result, row);
            break;
          case 1:
            this.setDunnag1(result, row);
            break;
          case 2:
            this.setDunnage2(result, row);
            break;
          case 3:
            this.setTopCap(result, row);
            break;
          case 4:
            this.setPallet(result, row);
            break;
          case 5:
            this.setStretch(result, row);
            break;
          case 6:
            this.setkanban(result, row);
            break;
          case 7:
            this.setOther(result, row);
            break;
          default:
            break;
        }
        this.form.updateValueAndValidity()
      }
    })
  }

  selectOnload(flag: any, _row: any) {
    const payload = {
      "PlantCode": this.selectParams.PlantCode ?? "",
      "PkgCode": this.selectParams.PkgCode ?? "",
      "FabCode": this.selectParams.FabCode ?? "",
      "TopPkgCode": flag === 1 ? (this.selectParams.PkgCode ?? "") : (this.selectParams.TopPkgCode ?? ""),
      "PalPkgCode": flag === 2 ? (this.selectParams.PkgCode ?? "") : (this.selectParams.PalPkgCode ?? "")
    }
    return new Promise((resolve, _rej) => {
      this.internalPpfService.getCostHeight(payload).subscribe({
        next: (res) => {
          this.selectedResponse = res
           this.hidheightCalculation.hidtopHeight=this.selectedResponse.TopHeight
          this.hidheightCalculation.hidpalHeight =this.selectedResponse.PalHeight
          resolve('')
        },
      })
    })
  }

  async setContainerValues(result: any, row: number) {
    await this.selectOnload(0, row)
    this.hidweightCalculations.hidContWeight = result.data?.weight
    this.hidweightCalculations.txtContPkg = result.data.packagecode	// PackageCode
    this.form.controls[`row${row}col4`].setValue(result.data.packagecode);
    this.data[row].col4 = result.data?.material;
    this.data[row].col2 = result.data?.description;
    this.data[row].col7 = result.data?.returnable;
    this.data[row].col6 = result.data?.leadtime;
    this.form.controls[`row6col7`].setValue(result.data?.qtykholder);
    this.form2.controls['row2col5'].setValue(this.setdimensions(result.data?.weight, 1));
    this.form2.controls['row2col7'].setValue(result.data?.qtylayerpallet);
    this.form2.controls['row1col7'].setValue(result.data?.qtycontlayer);
    this.tableData[1].column2 = this.setdimensions(result.data?.outerlength, 0);
    this.tableData[1].column3 = this.setdimensions(result.data?.outerwidth, 0);
    this.tableData[1].column4 = this.setdimensions(result.data?.outerheight, 0);
    this.tableData[2].column2 = this.setdimensions(result.data?.innerlength, 0);
    this.tableData[2].column3 = this.setdimensions(result.data?.innerwidth, 0);
    this.tableData[2].column4 = this.setdimensions(result.data?.innerheight, 0);
    this.tableData[3].column4 = this.setdimensions(result.data?.retheight, 0);
    this.newcalcWeight()
    this.setLoadPalletHeight(row)
  }

  setLoadPalletHeight(row: any) {
    if (!this.pkgDelete) {
      this.form.controls[`row${row}col8`].setValue(this.selectedResponse.Cost ?? null)
    }
    this.pkgDelete = false
    const mlp = toNumber(this.form2.controls['row2col7']?.value);
    const contheight = this.tableData[1].column4
    const loadheight = (toNumber(contheight) * mlp) + toNumber(this.setdimensions(this.selectedResponse.PalHeight,0)) + toNumber(this.setdimensions(this.selectedResponse.TopHeight,0))
    this.form2.controls['row5col3'].setValue(toNumber(loadheight))
  }

  async setDunnag1(result: any, row: number) {
    await this.selectOnload(0, row)
    this.hidweightCalculations.dun1Weight = result.data?.weight
    //grid1
    this.form.controls[`row${row}col4`].setValue(result.data.packagecode);
    this.data[row].col4 = result.data?.material;
    this.data[row].col2 = result.data?.description;
    this.data[row].col7 = result.data?.returnable;
    this.data[row].col6 = result.data?.leadtime;
    //grid2
    this.newcalcWeight()
    this.setLoadPalletHeight(row)
  }

  async setDunnage2(result: any, row: number) {
    await this.selectOnload(0, row)
    this.hidweightCalculations.dun2Weight = result.data?.weight
    //grid1
    this.form.controls[`row${row}col4`].setValue(result.data.packagecode);
    this.data[row].col4 = result.data?.material;
    this.data[row].col2 = result.data?.description;
    this.data[row].col7 = result.data?.returnable;
    this.data[row].col6 = result.data?.leadtime;
    //grid2
    this.newcalcWeight()
    this.setLoadPalletHeight(row)
  }

  async setTopCap(result: any, row: number) {
    await this.selectOnload(1, row)
    this.hidweightCalculations.topWeight = result.data?.weight
    //grid1
    this.form.controls[`row${row}col4`].setValue(result.data.packagecode);
    this.data[row].col4 = result.data?.material;
    this.data[row].col2 = result.data?.description;
    this.data[row].col7 = result.data?.returnable;
    this.data[row].col6 = result.data?.leadtime;
    //grid2
    this.newcalcWeight()
    this.setLoadPalletHeight(row)
  }

  async setPallet(result: any, row: number) {
    await this.selectOnload(2, row)
    this.hidweightCalculations.palWeight = result.data?.weight
    //gid1
    this.form.controls[`row${row}col4`].setValue(result.data.packagecode);
    this.data[row].col4 = result.data?.material;
    this.data[row].col2 = result.data?.description;
    this.data[row].col7 = result.data?.returnable;
    this.data[row].col6 = result.data?.leadtime;
    //grid2
    this.tableData[4].column2 = this.setdimensions(result.data?.outerlength, 0);
    this.tableData[4].column3 = this.setdimensions(result.data?.outerwidth, 0);
    this.tableData[4].column4 = this.setdimensions(result.data?.outerheight, 0);
    this.form2.controls['row3col5'].setValue(this.setdimensions(result.data?.weight, 1));
    this.newcalcWeight()
    this.setLoadPalletHeight(row)
  }

  async setStretch(result: any, row: number) {
    await this.selectOnload(0, row)
    //grid1
    this.form.controls[`row${row}col4`].setValue(result.data.packagecode);
    this.data[row].col4 = result.data?.material;
    this.data[row].col2 = result.data?.description;
    this.data[row].col7 = result.data?.returnable;
    this.data[row].col6 = result.data?.leadtime;
    this.newcalcWeight()
    this.setLoadPalletHeight(row)
  }

  async setkanban(result: any, row: number) {
    await this.selectOnload(0, row)
    //grid1
    this.form.controls[`row${row}col4`].setValue(result.data.packagecode);
    this.data[row].col4 = result.data?.material;
    this.data[row].col2 = result.data?.description;
    this.data[row].col7 = result.data?.returnable;
    this.data[row].col6 = result.data?.leadtime;
    this.newcalcWeight()
    this.setLoadPalletHeight(row)
  }

  async setOther(result: any, row: number) {
    await this.selectOnload(0, row)
    //grid1
    this.form.controls[`row${row}col4`].setValue(result.data.packagecode);
    this.data[row].col4 = result.data?.material;
    this.data[row].col2 = result.data?.description;
    this.data[row].col7 = result.data?.returnable;
    this.data[row].col6 = result.data?.leadtime;
    this.newcalcWeight()
    this.setLoadPalletHeight(row)
  }

  handleUpload(event: any, imageNumber: any) {
    const file = event.target.files[0];
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (imageNumber) {
        //get file type
        const prefix = 'data:image/';
        const base64Image: any = reader.result;
        const base64Parts = base64Image.split(';base64');
        const contentType = base64Parts[0].substring(prefix.length)
        //get file size
        const fileSizeInBytes = base64Image.length * 0.75;
        const fileSizeInKb = fileSizeInBytes / 1024;
        this.formatError[imageNumber - 1] = ''
        if (['jpeg', 'jpg', 'gif'].includes(contentType)) {
          if (fileSizeInKb < 200) {
            this.handleImageUpload(reader.result, file?.name, imageNumber);
          } else {
            this.formatError[imageNumber - 1] = 'Image size should be less than 200kb'
          }
        } else {
          this.formatError[imageNumber - 1] = 'Image should be of Jpg or Gif format'
        }
      }
    };
    reader.onerror = () => { alert('Error while uploading') }
  }

  handleDelete(imageNumber: number) {
    this.imageNames[imageNumber - 1] = ""
    this.image[imageNumber - 1] = ""
  }
   
  savePPF() {
    this.url = this.breadcrumb.breadcrumbs.length > 0 && this.breadcrumb.breadcrumbs.at(-2)?.url;
    let updatePPFReasons;
    if (sessionStorage.getItem('updatePPFReasons')) {
      updatePPFReasons = JSON.parse(sessionStorage.getItem('updatePPFReasons') || '');
    }
    if(this.url.includes('update-ppf-reason')){
      if(!updatePPFReasons){
        this.specialCharError = ['Reason What & Reason Why is mandatory'];
        return;
      }else{
        this.specialCharError = [];
      }
    }
    const body = this.getSavePPFBody();
    const specialChars = this.constantsService.findFieldsWithSpecialSymbols(body);
    if(specialChars.length){
      this.specialCharError = specialChars;
      return;
    }
    this.specialCharError = [];
    this.spinner.start();
    this.internalPpfService.savePPF(body).subscribe({
      next: (_res: any) => {
        if (_res[0].result || _res[0].reslt) {
          this.resetGlobalState();
          if (this.route.url.includes('benchmark')) {
            const params = JSON.parse(sessionStorage.getItem('benchMark') || '{}');
            sessionStorage.setItem('deptApprove', JSON.stringify(params));
            this.route.navigate(['/tracking-list/send-dept-approval']);
          } else {
            this.route.navigate([this.url]);
          }
        }
        this.spinner.stop();
      },
      error: (_err: any) => {
        this.spinner.stop();
      }
    });
  }

  resetGlobalState(){
    this.internalPpfService.table1Data = [];
    this.internalPpfService.table2Data = [];
    this.internalPpfService.newPackage = {
      row : 10,
      packagecode : ''
    }
  }

  getSavePPFBody() {
    let updatePPFReasons;
    if (sessionStorage.getItem('updatePPFReasons')) {
      updatePPFReasons = JSON.parse(sessionStorage.getItem('updatePPFReasons') || '');
    }
    return {
      "ppfCode": Number(this.routeParams?.ppfcode),
      "ppfVersion": Number(this.routeParams?.ppfver),
      "History": this.routeParams?.history,
      "userSpecialistCode": this.userInfo?.info?.specialistcode,
      "userPlantCode": this.userInfo?.info?.plantcode,
      "PlantList": this.userInfo?.role?.p_plantList,
      "Step": this.ppfData?.step,
      "validflag": true, //modify this
      "ContDesc": (this.data[0].col2!==undefined && this.data[0].col2!==null)? this.data[0].col2:(this.ppfData?.condescription),
      "ContMaterial": (this.data[0].col4!==undefined && this.data[0].col4!==null)? this.data[0].col4:(this.ppfData?.conmaterial),
      "ContLT": (this.data[0].col6)?this.data[0].col6:(this.ppfData?.conleadtime),
      "ContRetExp": (this.data[0].col7)?this.data[0].col7:(this.ppfData?.conretexp),
      "ContainerPkgCode": this.form.controls['row0col4']?.value,
      "ContainerManufacturer": this.form.controls['row0col2']?.value,
      "ContainerCostEst": this.form.controls['row0col8']?.value,
      "ContainerQty": this.form.controls['row0col7']?.value===''?null: this.form.controls['row0col7']?.value,
      "ContainerComment": this.form.controls['row0col9']?.value,
      "Dun1Desc": (this.data[1].col2!==undefined && this.data[1].col2!==null)? this.data[1].col2:(this.ppfData?.dun1description),
      "Dun1Material":(this.data[1].col4!==undefined && this.data[1].col4!==null)?this.data[1].col4: (this.ppfData?.dun1description),
      "Dun1LT": (this.data[1].col6)?this.data[1].col6:(this.ppfData?.dun1leadtime),
      "Dun1RetExp": (this.data[1].col7)?this.data[1].col7:(this.ppfData?.dun1retexp),
      "Dunnage1PkgCode": this.form.controls['row1col4']?.value,
      "Dunnage1Manufacturer": this.form.controls['row1col2']?.value,
      "Dunnage1Qty": this.form.controls['row1col7']?.value===''?null: this.form.controls['row1col7']?.value,
      "Dunnage1CostEst": this.form.controls['row1col8']?.value,
      "Dunnage1Comment": this.form.controls['row1col9']?.value,
      "Dun2Desc": (this.data[2].col2!==undefined && this.data[2].col2!==null)?this.data[2].col2: (this.ppfData?.dun2description),
      "Dun2Material": (this.data[2].col4!==undefined && this.data[2].col4!==null)? this.data[2].col4:(this.ppfData?.dun2material),
      "Dun2LT": (this.data[2].col6)?this.data[2].col6 :(this.ppfData?.dun2leadtime),
      "Dun2RetExp": (this.data[2].col7)?this.data[2].col7:(this.ppfData?.dun2retexp),
      "Dunnage2PkgCode": this.form.controls['row2col4']?.value,
      "Dunnage2Manufacturer": this.form.controls['row2col2']?.value,
      "Dunnage2Qty": (this.form.controls['row2col7'].value)===''?null:(this.form.controls['row2col7'].value),
      "Dunnage2CostEst": this.form.controls['row2col8']?.value,
      "Dunnage2Comment": this.form.controls['row2col9']?.value,
      "TopDesc": (this.data[3].col2!==undefined && this.data[3].col2!==null)?this.data[3].col2: (this.ppfData?.topdescription),
      "TopMaterial": (this.data[3].col4!==undefined && this.data[3].col4!==null)?this.data[3].col4: (this.ppfData?.topmaterial),
      "TopLT": (this.data[3].col6)?this.data[3].col6:(this.ppfData?.topleadtime),
      "TopRetExp": (this.data[3].col7)?this.data[3].col7 :(this.ppfData?.topretexp),
      "TopCapPkgCode": this.form.controls['row3col4']?.value,
      "TopCapManufacturer": this.form.controls['row3col2']?.value,
      "TopCapCostEst": this.form.controls['row3col8']?.value,
      "TopCapQty": this.form.controls['row3col7']?.value===''?null: this.form.controls['row3col7']?.value,
      "TopCapComment": this.form.controls['row3col9']?.value,
      "PalDesc": (this.data[4].col2!==undefined && this.data[4].col2!==null)? (this.data[4].col2):(this.ppfData?.paldescription),
      "PalMaterial": (this.data[4].col4!==undefined && this.data[4].col4!==null)?this.data[4].col4:(this.ppfData?.palmaterial),
      "PalLT": (this.data[4].col6)?(this.data[4].col6):(this.ppfData?.palleadtime),
      "PalRetExp": (this.data[4].col7)?this.data[4].col7:(this.ppfData?.palretexp),
      "PalletPkgCode": this.form.controls['row4col4']?.value,
      "PalletManufacturer": this.form.controls['row4col2']?.value,
      "PalletCostEst": this.form.controls['row4col8']?.value,
      "PalletQty": this.form.controls['row4col7']?.value===''?null: this.form.controls['row4col7']?.value,
      "PalletComment": this.form.controls['row4col9']?.value,
      "wrapDesc": (this.data[5].col2!==undefined && this.data[5].col2!==null) ? this.data[5].col2:(this.ppfData?.strdescription),
      "wrapMaterial": (this.data[5].col4!==undefined && this.data[5].col4!==null)?this.data[5].col4:(this.ppfData?.strmaterial),
      "wrapLT": (this.data[5].col6)?this.data[5].col6:(this.ppfData?.strleadtime),
      "wrapRetExp": (this.data[5].col7)?this.data[5].col7 :(this.ppfData?.strretexp),
      "WrapPkgCode": this.form.controls['row5col4']?.value,
      "WrapManufacturer": this.form.controls['row5col2']?.value,
      "WrapQty": this.form.controls['row5col7']?.value===''?null: this.form.controls['row5col7']?.value,
      "WrapCostEst": this.form.controls['row5col8']?.value,
      "WrapComment": this.form.controls['row5col9']?.value,
      "KanDesc": (this.data[6].col2!==undefined && this.data[6].col2!==null)?this.data[6].col2:(this.ppfData?.kandescription),
      "KanMaterial": (this.data[6].col4!==undefined && this.data[6].col4!==null)?this.data[6].col4:(this.ppfData?.kanmaterial),
      "KHLT": (this.data[6].col6)?this.data[6].col6:(this.ppfData?.kanleadtime),
      "KanRetExp": (this.data[6].col7)?this.data[6].col7:(this.ppfData?.kanretexp),
      "KHolderPkgCode": this.form.controls['row6col4']?.value,
      "KHolderManufacturer": this.form.controls['row6col2']?.value,
      "KHolderCostEst": this.form.controls['row6col8']?.value,
      "KHQty": this.form.controls['row6col7']?.value===''?null: this.form.controls['row6col7']?.value,
      "KHolderComment": this.form.controls['row6col9']?.value,
      "OthDesc": (this.data[7]?.col2!==undefined && this.data[7]?.col2!==null)?(this.data[7].col2):(this.ppfData?.othdescription),
      "OthMaterial": (this.data[7]?.col4!==undefined && this.data[7]?.col4!==null)?(this.data[7].col4):(this.ppfData?.othmaterial),
      "OTHLT": (this.data[7].col6)?this.data[7].col6:(this.ppfData?.othleadtime),
      "OthRetExp": (this.data[7].col7)?this.data[7].col7:(this.ppfData?.othretexp),
      "OtherPkgCode": this.form.controls['row7col4']?.value,
      "OtherPkgManufacturer": this.form.controls['row7col2']?.value,
      "OtherPkgQty": this.form.controls['row7col7']?.value===''?null: this.form.controls['row7col7']?.value,
      "OtherPkgCostEst": this.form.controls['row7col8']?.value,
      "OtherPkgComment": this.form.controls['row7col9']?.value,
      "PartNumber": this.ppfData?.partnumber,
      "PreOrFinal": this.ppfForm.controls.broadcastPart.value,
      "SupplierName": this.ppfData?.suppliername,
      "SupplierCode": this.ppfData?.suppliercode,
      "SupplierPickup": this.ppfData?.supplierpickup,
      "TLMS_Code": this.ppfData?.tlms_code,
      "SupplierContact": this.ppfData.suppliercontact,
      "SupplierPhoneNumber": this.ppfData?.supplierphonenumber,
      "SupplierFaxNumber": this.ppfData?.supplierfaxnumber,
      "SupplierMail": this.ppfData?.suppliermail,
      "ModelCode": this.ppfData?.modelcode,
      "PartDescription": this.ppfData?.description,
      "ReplacesPartNumber": this.ppfData?.replacespartnumber,
      "ECINumber": this.ppfData?.ecinumber,
      "RegDate": this.ppfData?.regdate,
      "DockCode": this.ppfForm.controls.dock.value,
      "PicPart": this.imageNames[0] ? this.imageNames[0] : null,
      "PicDunnage": this.imageNames[1] ? this.imageNames[1] : null,
      "PicContRack": this.imageNames[2] ? this.imageNames[2] : null,
      "PicPallet": this.imageNames[3] ? this.imageNames[3] : null,
      "Hazmat": this.ppfForm.controls.hazmat.value,
      "PartL": this.ppfData?.partl,
      "PartW": this.ppfData?.partw,
      "PartH": this.ppfData?.parth,
      "ContL": this.ppfData?.contl,
      "ContW": this.ppfData?.contw,
      "ContH": this.ppfData?.conth,
      "InContL": this.ppfData?.incontl,
      "InContW": this.ppfData?.incontw,
      "InContH": this.ppfData?.inconth,
      "ContRetH": this.ppfData?.contreth,
      "PalletOnlyL": this.ppfData?.palletonlyl,
      "PalletOnlyW": this.ppfData?.palletonlyw,
      "PalletOnlyH": this.ppfData?.palletonlyh,
      "PalletLoadL": this.ppfData?.palletloadl,
      "PalletLoadW": this.ppfData?.palletloadw,
      "PalletLoadH": this.form2.get('row5col3')?.value,
      "PartWeight": this.ppfData?.partweight,
      "DunnageWeight": this.form2.get('row1col5')?.value,
      "ContRackWeight": this.form2.get('row2col5')?.value,
      "PalletWeight": this.form2.get('row3col5')?.value,
      "ContRackGrossWeight": this.form2.get('row4col5')?.value,
      "PalletLoadGrossWeight": this.form2.get('row5col5')?.value,
      "QtyPartContRack": this.form2.get('row0col7')?.value,
      "QtyMaxContLayer": this.form2.get('row1col7')?.value,
      "QtyMaxLayerPallet": this.form2.get('row2col7')?.value,
      "Note": this.ppfData?.note,
      "ConnectPPF": this.ppfData?.connectppf,
      "ConnectLevel": this.ppfData?.connectlevel,
      "SupplierApprove": this.ppfData?.supplierapprove,
      "SupplierDate": this.ppfData?.supplierdate,
      "Approver": this.ppfData?.approver,
      "ApproveDate": this.ppfData?.approverdate,
      "Affiliate": this.ppfData?.affiliate,
      "SupplierGuid": this.ppfData?.supplierguid,
      "FabricatorGuid": this.ppfData?.fabricatorguid,
      "url_selWhat": updatePPFReasons?.what,
      "url_selwhy": updatePPFReasons?.why,
      "url_txtwhat": updatePPFReasons?.whyDesc ?? '',
      "url_txtwhy": updatePPFReasons?.whyDesc ?? '',
      "BroadcastFlag": Number(this.ppfForm.controls.broadcastPart.value),
      "SupInventoryReq": this.form2.get('row4col7')?.value,
      "SupInventoryAllo": this.form2.get('row5col7')?.value,
      "updateMode": this.url.includes('part-list') ? "99" : (this.url.includes('update-ppf-reason') ? "99": null),
      "Screen_mode": this.response.Mode === 0 ? "Add" : "Update"
    }
  }

  help() {
    this.route.navigate(['./master-help', 2, "Tracking", 25])
  }

  handleImageUpload(base64: any, filename: any, number: any){
    if (filename !== undefined && filename !== null) {
      const checkCharacters = this.constantsService.checkFilenameHasMoreThanFifyChars(filename)
      if(checkCharacters.length > 0){
        this.specialCharError = checkCharacters;
        return;
      }else{
        this.specialCharError = [];
      }
    }
    if (base64) {
      const body = {
        "PPFCode": this.ppfData?.ppfcode || this.routeParams?.ppfcode,
        "PPFVer": this.response?.PPFVer,
        "pType": number,
        "File": {
          "FileName": filename,
          "Data": base64.split(',')[1]
        }
      };

      this.spinner.start();
      this.internalPpfService.uploadImage(body).subscribe({
        next: (_res: any) => {
          if (_res.status) {
            this.imageNames[number - 1] = _res?.response?.imageName;
            this.image[number - 1] = this.sanitizer.sanitize(SecurityContext.URL, base64);
          }
          this.spinner.stop();
        },
        error: (_err: any) => {
          this.spinner.stop();
        }
      });
    }
  }

  downloadPPF() {
    const body = {
      "PPFCode": this.routeParams.ppfcode,
      "PPFVer": this.response.PPFVer,
      "PlantCode": this.userInfo.info?.plantcode,
      "Mode": this.response.Mode,
      "Unit": this.userInfo?.info?.unit,
      "SupplierType": this.userInfo?.role?.supType
    }
    this.spinner.start();
    this.internalPpfService.DownloadPPF(body).subscribe({
      next: (res: any) => {
        const base64 = res?.Data;
        const fileName = res?.Filename;
        const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
        FileSaver.saveAs(file, fileName);
        this.spinner.stop();
      },
      error: (_err: any) => {
        this.spinner.stop();
      }
    })
  }

  checkText(val: any, fl: any) {
    let obj
    if (fl === 1) {
      if (this.form.controls[val]?.value?.toString().includes('PEND')) {
        obj = { setbackground: true }
      } else {
        obj = { setbackground: false }
      }
    } else {
      if (val.includes('$') && fl === 0) {
        const data1 = val.split('$')
        if (data1[1] === 5) {
          if (this.data[data1[0]]['col' + (data1[1])]?.toString().includes('PEND')) {
            obj = { setbackground: true }
          }
        }
      }
    }
    return obj
  }

  contGross(i: any, j: any, flag: any) {
   const limit= (this.userInfo.info.unit === '0') ? 15: roundEx(KgToPound(15),1); 
    if ((flag===0 || flag === 1) && (i === 4 && j === 5)) {
      if (Number(this.form2.get('row4col5')?.value) >= Number(limit)) {
        return { setbackgroundContGross: true };
      }else{
        return { setbackgroundContGross: false };
      }
    }
    return { setbackgroundContGross: false };
  }
  
  close(){
    this.resetGlobalState();
    this.errorMessage = null;
  }

}