import { Component, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConstantsService } from 'src/app/services/constant.service';
import * as json from '../../../../../../assets/envConst.json';

@Component({
  selector: 'app-ppf-btn',
  templateUrl: './ppf-btn.component.html',
  styleUrls: ['./ppf-btn.component.scss']
})
export class PpfBtnComponent implements AfterViewInit {
  params: any;
  bm = false;
  se = false;
  ia = false;
  isText = false;
  text: any;
  drv: any = {}
  step = "";
  jsFunction = "";
  switchMethod = 0;
  textNode = ""
  val = ""
  hazmatAccess = false;
  hzPlants : any;
  constructor(private readonly router: Router, private readonly route: ActivatedRoute,
    public readonly dialog: MatDialog, private readonly renderer: Renderer2, private readonly elementRef: ElementRef,
    private readonly constants: ConstantsService) { }

  agInit(params: any): void {
    this.hzPlants = JSON.parse(JSON.stringify(json))?.hazmatReqPlants;
    this.val = ""
    this.drv = {};
    this.params = params;
    this.drv = params.data;
    this.hazmatAccess = this.drv.userInfo.role?.u_roles.includes('TMNA Hazmat Admin') ? true : false;
    if (this.params.colDef.field === "step02date") {
      if (this.nullStr(params.data["step02status"]) === "1" || this.nullStr(params.data["step02status"]) === "14") {
        this.val = "BM";
      } else {
        this.val = "SE";
      }
      this.jsFunction = "openSendDepartmentApproval"
      this.step = "step02"
    } else if (this.params.colDef.field === "step04date") {
      if (this.nullStr(params.data["step04status"]) === "1" || this.nullStr(params.data["step04status"]) === "14") {
        if (params.data?.hazmat === "Y" && params.data?.hzapprovestatus !== "A" && this.hzPlants.includes(params.data?.plantcode)) {
          this.val = "HZ";
        }
        else {
          this.val = "SI";
        }
      }
      else {
        this.val = "SE";
      }
      this.jsFunction = "openSendDepartmentApproval"
      this.step = "step04"
    } else if (this.params.colDef.field === "step05date") {
      this.val = "R";
      this.jsFunction = "openTrialOrder"
      this.step = "step05"
    } else if (this.params.colDef.field === "step07date") {
      if (this.nullStr(params.data["step07status"]) === "1" || this.nullStr(params.data["step07status"]) === "14") {
        if ((params.data?.hazmat === "Y" || params.data?.hazmat === "y" || params.data?.hazmat === "Yes")
          && params.data?.hzapprovestatus !== "A"  && this.hzPlants.includes(params.data?.plantcode))  {
          this.val = "HZ";
        }
        else {
          this.val = "SI";
        }
      } else {
        this.val = "SE";
      }
      this.jsFunction = "openSendDepartmentApproval"
      this.step = "step07"
    } else if (this.params.colDef.field === "step09date") {
      if (this.nullStr(params.data["step09status"]) === "1" || this.nullStr(params.data["step09status"]) === "14") {
        if (params.data?.hazmat === "Y" && params.data?.hzapprovestatus !== "A" && this.hzPlants.includes(params.data?.plantcode)) {
          this.val = "HZ";
        }
        else {
          this.val = "SI";
        }
      } else {
        this.val = "SE";
      }
      this.jsFunction = "openSendDepartmentApproval"
      this.step = "step09"
    } else if (this.params.colDef.field === "step10date") {
      this.val = "R";
      this.jsFunction = "openRCAF"
      this.step = "step10"
    }
  }

  ngAfterViewInit() {
    this.createHtmlTrackingCell();
  }

  private createHtmlTrackingCell() {
    const drv = this.drv;
    const step = this.step;
    const val = this.val;
    const spcode = drv.specialistcode;
    const follower = drv.follower;
    const jsFunction = this.jsFunction;
    const status = this.nullStr(drv[step + "status"]);
    const cmdTracking = "cmd_tracking_ia vpacs-btn-table";
    let htmlButton: any;
    const plantCode = this.constants.getTypeFromPlantCode(drv.plantcode)
    if (status === "1" || status === "13" || status === "14") {
      if (val === "BM" && plantCode !== 'SERVICE' &&  plantCode !== 'ASSY') {
        this.renderButtontoUI("openBM", step, val, spcode, follower);

        if (this.nullStr(drv["leaderplant"]) !== "" && status === "14") {
          this.renderButtontoUI(jsFunction, step, "SI", spcode, "");
        } else {
          this.renderButtontoUI(jsFunction, step, "SI", spcode, follower);
        }
      }
      else if (val === "BM" && (plantCode === 'SERVICE' || plantCode === 'ASSY')) {
        if (drv.step02status !== '4') {
          if (this.nullStr(drv["leaderplant"]) !== "" && status === "14") {
            this.renderButtontoUI(jsFunction, step, "SI", spcode, "");
          } else {
            this.renderButtontoUI(jsFunction, step, "SI", spcode, follower);
          }
        }
        else {
          this.createText(this.changeNullData(drv[step + "date"]) + "\n");
        }
      }
      else if (val === "SE" || val === "SI" || val === "HZ") {
        if (val === "HZ") {
          this.renderButtontoUI("openHazmatApprove", step, val, spcode, "");
        }
        else {
          if (this.nullStr(drv["leaderplant"]) !== "" && (status === "13" || status === "14")) {
            this.renderButtontoUI(jsFunction, step, val, spcode, "");
          } else {
            this.renderButtontoUI(jsFunction, step, val, spcode, follower);
          }
        }

      } else if (val === "R" && step === "step10" && this.nullStr(follower) !== "") {
        this.createText("-");
      } else {
        this.renderButtontoUI(jsFunction, step, val, spcode, follower);
      }
    } else if (status === "2") {
      this.createText("wait AM");
    } else if (status === "3") {
      this.createText("wait MGR");
    } else if (status === "4") {
      this.createText(this.changeNullData(drv[step + "date"]) + "\n");
    } else if (status === "5") {
      if (step === "step07" && this.nullStr(drv["parentpartnumber"]) !== "" && this.nullStr(drv["parentpartnumber"]) !== null) {
        this.createText(this.changeNullData(this.nullStr(drv["parentpartnumber"])) + "\n");
      } else {
        this.createText("skip ");
      }
    } else if (status === "6") {
      this.createText("PUR SP");
    } else if (status === "7") {
      this.createText("PUR AM");
    } else if (status === "8") {
      this.createText("PUR MGR");
    } else if (status === "12") {
      if (this.nullStr(drv["leaderplant"]) !== "" && status === "12") {
        if (spcode === this.drv.userInfo.info.specialistcode) {
          htmlButton = this.returnButton("openIntAppList", step, "IA", spcode, "", cmdTracking, "4");
        } else {
          htmlButton = this.returnButton("openIntAppList", step, "IA", spcode, "", cmdTracking, "2");
        }
      } else {
        if (spcode === this.drv.userInfo.info.specialistcode) {
          htmlButton = this.returnButton("openIntAppList", step, "IA", spcode, follower, cmdTracking, "4");
        } else {
          htmlButton = this.returnButton("openIntAppList", step, "IA", spcode, follower, cmdTracking, "2");
        }
      }

      if (htmlButton?.disabled) {
        const approvers = this.drv?.approveArray;
        if (approvers.length > 0) {
          for (const value of approvers) {
            if (value?.currentapprovercode === this.drv.userInfo.info.specialistcode) {
              htmlButton.disabled = false;
            }
          }
        }
      }
      htmlButton && this.renderer.appendChild(this.elementRef.nativeElement, htmlButton);
    } else if (status === "20") {
      this.createText(this.changeNullData(drv[step + "date"]) + "\n");
    } else {
      this.createText("-");
    }

    if (this.nullStr(drv["leaderplant"]) === "" && !(this.nullStr(drv["step04status"]) === "1" || this.nullStr(drv["step04status"]) === "13" || this.nullStr(drv["step04status"]) === "14")) {
      if (this.nullInt(step.substring(4)) > 2 && this.nullStr(drv["step03status"]) !== "0" && drv.dispQ) {
        if (this.nullStr(drv["step04status"]) !== "12" && this.textNode === "-") {
          this.createText("");
        }
        this.renderButtontoUI("openQuestionnaire", step, "SQ", spcode, follower, cmdTracking);
        drv.dispQ = false;
      }
    }
  }

  renderButtontoUI(functionName: string, step: string, val: string, spcode: string, follower: string, csscls?: string) {
    const cls = csscls ? csscls : "vpacs-btn-table blue";
    const dataBs = 'data-bs-toggle';
    const dataPlacement = 'data-bs-placement';
    const plantCode = this.constants.getTypeFromPlantCode(this.drv.plantcode)
    let htmlButton: any;
    let text = false;
    if (functionName === "openBM") {
      htmlButton = this.createHtmlButton(`btnBM${step}_${this.drv.index}`, val, cls, spcode, follower);
      this.renderer.listen(htmlButton, 'click', () =>
        this.openBM(val)
      );
      this.renderer.listen(htmlButton, 'mouseenter', () => {
        this.renderer.setAttribute(htmlButton, dataBs, "tooltip");
        this.renderer.setAttribute(htmlButton, dataPlacement, "top");
        this.renderer.setAttribute(htmlButton, 'title', 'Benchmark');
      });
    } else if (functionName === "openSendDepartmentApproval") {
      if ((plantCode === 'SERVICE' || plantCode === 'ASSY') && this.drv.step02status === '4' && step !== 'step09') {
        this.createText('skip')
        text = true
      }
      else {
        htmlButton = this.createHtmlButton(`btn${step}_${this.drv.index}`, val, cls, spcode, follower);
        this.renderer.listen(htmlButton, 'click', () =>
          this.openSendDepartmentApproval(val)
        );
        this.renderer.listen(htmlButton, 'mouseenter', () => {
          this.renderer.setAttribute(htmlButton, dataBs, "tooltip");
          this.renderer.setAttribute(htmlButton, dataPlacement, "top");
          this.renderer.setAttribute(htmlButton, 'title', val === 'SI' ? 'Send to Internal' : 'Send to Supplier / Fabricator');
        });
      }
    } else if (functionName === "openTrialOrder") {
      if ((plantCode === 'SERVICE' || plantCode === 'ASSY') && this.drv.step02status === '4') {
        this.createText('skip')
        text = true
      }
      else {
        htmlButton = this.createHtmlButton(`btn${step}_${this.drv.index}`, val, cls, spcode, follower);
        this.renderer.listen(htmlButton, 'click', () =>
          this.openTrialOrder()
        );
        this.renderer.listen(htmlButton, 'mouseenter', () => {
          this.renderer.setAttribute(htmlButton, dataBs, "tooltip");
          this.renderer.setAttribute(htmlButton, dataPlacement, "top");
          this.renderer.setAttribute(htmlButton, 'title', 'Trial Order');
        });
      }
    } else if (functionName === "openRCAF") {
      if ((plantCode === 'SERVICE' || plantCode === 'ASSY') && this.drv.step09status === '4') {
        this.createText('skip')
        text = true
      }
      else {
        htmlButton = this.createHtmlButton(`btn${step}_${this.drv.index}`, val, cls, spcode, follower);
        this.renderer.listen(htmlButton, 'click', () =>
          this.openRCAF(val)
        );
        this.renderer.listen(htmlButton, 'mouseenter', () => {
          this.renderer.setAttribute(htmlButton, dataBs, "tooltip");
          this.renderer.setAttribute(htmlButton, dataPlacement, "top");
          this.renderer.setAttribute(htmlButton, 'title', 'RCAF');
        });
      }
    } else if (functionName === "openQuestionnaire") {
      htmlButton = this.createHtmlButton(`btn${step}_${this.drv.index}`, val, cls, spcode, follower);
      this.renderer.listen(htmlButton, 'click', () =>
        this.openQuestionnaire()
      );
      this.renderer.listen(htmlButton, 'mouseenter', () => {
        this.renderer.setAttribute(htmlButton, dataBs, "tooltip");
        this.renderer.setAttribute(htmlButton, dataPlacement, "top");
        this.renderer.setAttribute(htmlButton, 'title', 'Supplier Questionnaire');
      });
    }
    else if (functionName === "openHazmatApprove") {
      if ((plantCode === 'SERVICE' || plantCode === 'ASSY') && this.drv.step02status === '4' && this.drv.step09status !== '1') {
        this.createText('skip')
        text = true
      }
      else {
        htmlButton = this.createHtmlButton(`btn${step}_${this.drv.index}`, val, cls, spcode, follower);
        this.renderer.listen(htmlButton, 'click', () =>
          this.openHazmat(val)
        );
        this.renderer.listen(htmlButton, 'mouseenter', () => {
          this.renderer.setAttribute(htmlButton, dataBs, "tooltip");
          this.renderer.setAttribute(htmlButton, dataPlacement, "top");
          this.renderer.setAttribute(htmlButton, 'title', 'Hazmat Approval');
        });
      }
    }
    if(!text){
      this.renderer.appendChild(this.elementRef.nativeElement, htmlButton);
    }
  }

  returnButton(functionName: string, step: string, val: string, spcode: string, follower: string, cls: string, arg: string) {
    let htmlButton: any;

    if (functionName === "openIntAppList") {
      htmlButton = this.createHtmlButton(`btn${step}_${this.drv.index}`, val, cls, spcode, follower);
      this.renderer.listen(htmlButton, 'click', () =>
        this.openIntAppList(arg)
      );
      this.renderer.listen(htmlButton, 'mouseenter', () => {
        this.renderer.setAttribute(htmlButton, 'data-bs-toggle', "tooltip");
        this.renderer.setAttribute(htmlButton, 'data-bs-placement', "top");
        this.renderer.setAttribute(htmlButton, 'title', 'Approving by Internal Docks');
      });
    }
    return htmlButton;
  }

  openBM(val: string) {
    sessionStorage.setItem('benchMark', JSON.stringify(this.params.data))
    if (this.params.colDef.field === 'step02date' && val === 'BM') {
      this.router.navigate(['/tracking-list/benchmark']);
    }
  }

  openSendDepartmentApproval(val: string) {
    sessionStorage.setItem('deptApprove', JSON.stringify(this.params.data))
    const sendppf = '/tracking-list/send-ppf';
    if (this.params.colDef.field === 'step02date' && val === 'SI') {
      this.router.navigate(['/tracking-list/send-dept-approval']);
    }
    if (this.params.colDef.field === 'step02date' && val === 'SE') {
      sessionStorage.setItem('step', '2')
      this.router.navigate([sendppf]);
    }

    if (this.params.colDef.field === 'step04date' && val === 'SI') {
      this.router.navigate(['/tracking-list/sI-preppf-dept-approval']);
    }
    if (this.params.colDef.field === 'step04date' && val === 'SE') {
      sessionStorage.setItem('step', '4')
      this.router.navigate([sendppf]);
    }

    if (this.params.colDef.field === 'step07date' && val === 'SI') {
      this.router.navigate(['/tracking-list/si-pkgtrial-dept-approval']);
    }
    if (this.params.colDef.field === 'step07date' && val === 'SE') {
      sessionStorage.setItem('step', '7')
      this.router.navigate([sendppf]);
    }
    if (this.params.colDef.field === 'step09date' && val === 'SI') {
      this.router.navigate(['/tracking-list/sI-final-dept-approval']);
    }
    if (this.params.colDef.field === 'step09date' && val === 'SE') {
      sessionStorage.setItem('step', '9')
      this.router.navigate([sendppf]);
    }
  }

  openRCAF(val: string) {
    if (this.params.colDef.field === 'step10date' && val === 'R') {
      const obj = {
        authNumber: this.params.data?.authnumber,
        specialistCode: this.params.data?.specialistcode,
        plantCode: this.params.data?.plantcode,
        flag: '',
        pkgstartdate: this.params.data?.pkgstartdate,
        partNumber: this.params.data?.partnumber,
        modelcode: this.params.data?.modelcode,
        familycode: this.params.data?.familycode,
        suppliercode: this.params.data?.suppliercode,
        screen: 'tracking-R',
        mode: 0
      }
      sessionStorage.setItem('createRCAF', JSON.stringify(obj))
      this.router.navigate(['/tracking-list/create-rcaf']);
    }
  }

  openTrialOrder() {
    if (this.params.colDef.field === 'step05date') {
      sessionStorage.setItem('trialOrder', JSON.stringify(this.params.data));
      this.router.navigate(['/tracking-list/trial-order']);
    }
  }

  openIntAppList(_flg: string) {
    sessionStorage.setItem('intApprove', JSON.stringify(this.params.data));
    this.router.navigate(['/tracking-list/internal-approvement']);
  }

  openQuestionnaire() {
    sessionStorage.setItem('deptApprove', JSON.stringify(this.params.data));
    this.router.navigate(['/tracking-list/sq-dept-approval']);
  }

  openHazmat(_val: any) {
    let step = 0;
    if (this.params.colDef.field === "step04date") {
      step = 4;
    }
    else if (this.params.colDef.field === "step07date") {
      step = 7;
    }
    else if (this.params.colDef.field === "step09date") {
      step = 9;
    }
    sessionStorage.setItem('currentStep', JSON.stringify(step))
    sessionStorage.setItem('hazmatApprove', JSON.stringify(this.params.data));
    this.router.navigate(['/tracking-list/hazmat-approval']);
  }

  private createHtmlButton(id: string, val: string, cls: string, spcode: string, follower: string) {
    try {
      const buttonElement = this.renderer.createElement('button');
      this.renderer.setAttribute(buttonElement, 'id', id);
      this.renderer.setProperty(buttonElement, 'innerText', val);
      this.renderer.setAttribute(buttonElement, 'class', cls);

      if (val === "P" || val === "T" || val === "F" || val === "R" || val === "S" || val === "BM"
        || val === "IA" || val === "SI" || val === "SE" || val === "SQ" || val === 'HZ') {
        if (val === 'HZ') {
          if (this.hazmatAccess === false) {
            this.setPropertyDisabled(spcode, buttonElement);
          }
          else {
            if (spcode !== this.drv.userInfo.info.specialistcode) {
              this.setPropertyDisabled(spcode, buttonElement);
            }
          }
        }
        else {
          if (spcode !== this.drv.userInfo.info.specialistcode) {
            this.setPropertyDisabled(spcode, buttonElement);
          }
          else {
            if (val !== "R" && this.nullStr(follower) !== "") {
              this.setPropertyDisabled(spcode, buttonElement);
            } else if (val === "R" && id.startsWith("btnStep05") && this.nullStr(follower) !== "") {
              this.setPropertyDisabled(spcode, buttonElement);
            }
          }
        }
      }
      return buttonElement;
    } catch (error) {
      return null;
    }
  }

  private createText(text: string) {
    this.textNode = this.renderer.createText(text);
    this.renderer.appendChild(this.elementRef.nativeElement, this.textNode);
  }


  btnClickedHandler(event: any) {
    if (event === 'BM') {
      const backMarkParams = {
        "modelcode": this.params.data.modelcode,
        "familycode": this.params.data.familycode,
        "plantcode": this.params.data.plantcode,
        "partnumber": this.params.data.partnumber,
        "suppliercode": this.params.data.suppliercode,
        "fromdate": this.params.data.pkgstartdate,
        "ppfcode": this.params.data.ppfcode,
        "ppfver": this.params.data.ppfver,
        "sessionSpecialistCode": sessionStorage.getItem('specialistCode') ? sessionStorage.getItem('specialistCode') : '',
        "sessionBackup": [],
      }
      sessionStorage.setItem('benchMark', JSON.stringify(backMarkParams));
      this.router.navigate(['/tracking-list/benchmark']);
    }
    else if (event === 'SI') {
      sessionStorage.setItem('deptApprove', JSON.stringify(this.params.data));
      this.router.navigate(['/tracking-list/send-dept-approval']);
    }
    else if (event === 'SE') {
      const { plantcode, partnumber, suppliercode, pkgstartdate, ppfcode, ppfver } = this.params.data

      this.router.navigate(['/tracking-list/send-ppf'],
        {
          queryParams: {
            data: JSON.stringify({ plantcode, partnumber, suppliercode, pkgstartdate, ppfcode, ppfver })
          }
        }
      );
    }
    else if (event === 'IA') {
      sessionStorage.setItem('intApprove', JSON.stringify(this.params.data));
      this.router.navigate(['/tracking-list/internal-approvement']);
    }
  }

  private nullStr(value: any): string {
    if (value === undefined || value === 'undefined' || value === null) {
      return "";
    }
    else {
      return value.toString();
    }
  }

  private nullInt(obj: any) {
    if (obj === null) {
      return 0;
    }
    else {
      return parseInt(obj)
    }
  }

  private changeNullData(data: string) {
    if (data === null || data?.length === 0) {
      return " - ";
    } else {
      return data;
    }
  }

  setPropertyDisabled(spcode: any, buttonElement: any) {
    this.renderer.setProperty(buttonElement, 'disabled', true);
    for (const value of this.drv.userInfo.role.backupDetails) {
      if (spcode === value?.specialistcode) {
        this.renderer.setProperty(buttonElement, 'disabled', false);
      }
    }
  }
}

