import { Location } from "@angular/common";
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { InteractionType } from "@azure/msal-browser";
import { Minimatch } from "minimatch";
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { ExportStateService } from "../services/export-state.service";
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from './constants';
import { MsalGuardConfiguration } from './msal.guard.config';
import { MsalInterceptorConfig } from './msal.interceptor.config';
import { MsalService } from './msal.service';
import { ConstantsService } from "../services/constant.service";

@Injectable()
export class MsalGuard implements CanActivate {
	constructor(
		@Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
		@Inject(MSAL_INTERCEPTOR_CONFIG) private readonly msalInterceptorConfig: MsalInterceptorConfig,
		private readonly authService: MsalService,
		private readonly location: Location,
		private readonly state: ExportStateService,
		private readonly constants : ConstantsService
	) { }

	/**
	 * Builds the absolute url for the destination page
	 * @param path Relative path of requested page
	 * @returns Full destination url
	 */
	getDestinationUrl(path: string): string {
		const baseElements = document.getElementsByTagName("base");
		const baseUrl = this.location.normalize(baseElements.length ? baseElements[0].href : window.location.origin);
		const pathUrl = this.location.prepareExternalUrl(path);
		if (pathUrl.startsWith("#")) {
			return `${baseUrl}/${pathUrl}`;
		}
		return `${baseUrl}${path}`;
	}

	private loginInteractively(url: string): Observable<boolean> {
		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			return this.authService.loginPopup({ ...this.msalGuardConfig.authRequest })
				.pipe(
					map(() => true),
					catchError(() => of(false))
				);
		}
		const redirectStartPage = this.getDestinationUrl(url);
		this.authService.loginRedirect({
			redirectStartPage,
			scopes: [],
			...this.msalGuardConfig.authRequest,
		});
		return of(false);
	}

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
		return this.authService.handleRedirectObservable()
			.pipe(
				concatMap(() => {
					if (!this.authService.getAllAccounts().length) {
						return this.loginInteractively(state.url);
					}
					return this.setUserInfo();
				}),
				catchError(() => {
					return of(false)
				})
			);
	}

	setUserInfo() {
		const graphurl = 'https://graph.microsoft.com/v1.0/me'
		const scopes: any = this.getScopesForEndpoint(graphurl);
		const account = this.authService.getAllAccounts()[0];
		return this.authService.acquireTokenSilent({ scopes, account })
			.pipe(
				catchError(() => {
					if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
						return this.authService.acquireTokenPopup({ ...this.msalInterceptorConfig.authRequest, scopes });
					}
					const redirectStartPage = window.location.href;
					this.authService.acquireTokenRedirect({ ...this.msalInterceptorConfig.authRequest, scopes, redirectStartPage });
					return EMPTY;
				}),
				switchMap((result: any) => {
					let valid = false;
					if (result.idToken) {
						valid = true;
						this.state.toyotaGUID = result?.idToken;
						sessionStorage.setItem('idToken', result?.idToken);
					}
					if (valid && ('ToyotaGUID' in result.idTokenClaims) && result.idTokenClaims['ToyotaGUID'].length) {
						this.state.setGUIDState(result.idTokenClaims['ToyotaGUID']);
						sessionStorage.setItem('exp', result.idTokenClaims['exp'])
						localStorage.setItem('toyotaGUID', JSON.stringify(result.idTokenClaims['ToyotaGUID']));
						let roles: any = result.idTokenClaims['roles'];

    				

            roles = roles.filter((x:any) => this.constants.vpacsAll.includes(x));
						if(sessionStorage.getItem('preferredRoles')){
							const arr1 = JSON.parse(sessionStorage.getItem('preferredRoles') || '{}').sort();
							const arr2 = roles.sort();
							if(JSON.stringify(arr1) === JSON.stringify(arr2)){
							  roles = JSON.parse(sessionStorage.getItem('preferredRoles') || '{}');
							  localStorage.removeItem('roles');
							  localStorage.setItem('roles', JSON.stringify(roles));
							}else{
								localStorage.setItem('roles', JSON.stringify(roles));
							}
						}else{
    						roles = this.state.swapRoles(roles,this.constants.vpacsAll);
							localStorage.setItem('roles', JSON.stringify(roles));
						}
						return of(true);
					}
					else {
						return of(false);
					}
				})
			);
	}

	private getScopesForEndpoint(endpoint: string): any {
		const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys());
		const keyMatchesEndpointArray = protectedResourcesArray.filter(key => {
			const minimatch = new Minimatch(key);
			return minimatch.match(endpoint) || endpoint.indexOf(key) > -1;
		});

		if (keyMatchesEndpointArray.length > 0) {
			const keyForEndpoint: any = keyMatchesEndpointArray[0];
			if (keyForEndpoint) {
				return this.msalInterceptorConfig.protectedResourceMap.get(keyForEndpoint);
			}
		}
		return null;
	}

}
