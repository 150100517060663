import {
	HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthenticationResult, InteractionType } from "@azure/msal-browser";
import { Minimatch } from "minimatch";
import { EMPTY, Observable, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ExportStateService } from '../services/export-state.service';
import { MSAL_INTERCEPTOR_CONFIG } from './constants';
import { MsalInterceptorConfig } from './msal.interceptor.config';
import { MsalService } from './msal.service';
import { MsalGuard } from './msal.guard';

@Injectable()
export class MsalInterceptor implements HttpInterceptor {

	constructor(
		@Inject(MSAL_INTERCEPTOR_CONFIG) private readonly msalInterceptorConfig: MsalInterceptorConfig,
		private readonly authService: MsalService, private readonly state: ExportStateService, public msal: MsalGuard
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const scopes:any = this.getScopesForEndpoint(req.url);
		const account = this.authService.getAllAccounts()[0];
		if (!scopes || scopes.length === 0) {
			const headers = req.headers.set('Authorization', sessionStorage.getItem('idToken') || this.state.toyotaGUID);
			const requestClone = req.clone({ headers });
			if (sessionStorage.getItem('exp')) {
				const exp = Number(sessionStorage.getItem('exp'));
				const currentTime = Date.now() / 1000;
				if (exp < currentTime) {
					sessionStorage.removeItem('exp');
					return this.msal.setUserInfo().pipe(
						switchMap(() => {
							return this.authService.acquireTokenSilent({ scopes, account }).pipe(
								switchMap((result: AuthenticationResult) => {
									sessionStorage.setItem('idToken', result?.idToken);
									const headers = req.headers.set('Authorization', result?.idToken);
									const requestClone = req.clone({ headers });
									return next.handle(requestClone);
								}),
								catchError(() => {
									return EMPTY;
								})
							);
						})
					);
				}
			}
			return next.handle(requestClone);
		}

		return this.authService.acquireTokenSilent({ scopes, account })
			.pipe(
				catchError(() => {
					if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
						return this.authService.acquireTokenPopup({ ...this.msalInterceptorConfig.authRequest, scopes });
					}
					const redirectStartPage = window.location.href;
					this.authService.acquireTokenRedirect({ ...this.msalInterceptorConfig.authRequest, scopes, redirectStartPage });
					return EMPTY;
				}),
				switchMap((result: AuthenticationResult) => {
					const headers = req.headers
						.set('Authorization', result.accessToken);

					const requestClone = req.clone({ headers });
					return next.handle(requestClone);
				})
			);

	}

	private getScopesForEndpoint(endpoint: string): Array<string> | null {
		const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys());
		const keyMatchesEndpointArray = protectedResourcesArray.filter(key => {
			const minimatch = new Minimatch(key);
			return minimatch.match(endpoint) || endpoint.indexOf(key) > -1;
		});
		let newReturnValue: any = []
		if (keyMatchesEndpointArray.length > 0) {
			const keyForEndpoint = keyMatchesEndpointArray[0];
			if (keyForEndpoint) {
				newReturnValue = this.msalInterceptorConfig.protectedResourceMap.get(keyForEndpoint);
			}
		}
		return newReturnValue;
	}

}
