import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  constructor(public router:Router,public dialogRef: MatDialogRef<LogoutComponent>) { }

  ngOnInit(): void {
  }

  logout(){
    this.dialogRef.close(true)
  }

  getSilentToken(){
    this.dialogRef.close(false);
  }

}
